import * as React from 'react';
import { GetAllToursBySectionIdPublic } from '../../../../../../../../Services/WebsiteSections/WebsiteSections.Service';
import { RectangleBestTours } from '../../../../../../../../UiComponentes/RectangleBestTours/Rectangle.BestTours';
import { Button, Grid } from '@material-ui/core';
import { useLocalStorage } from '../../../../../../../../Hooks';
import { useSelector } from 'react-redux';
import { GetRecommendedTourCitiesPublicFilterBy } from '../../../../../../../../Services';

export const BestToursView = (props: any) => {
  const [language] = useLocalStorage<{ currentLanguage: string; isRtl: boolean }>('localization', {
    currentLanguage: 'en',
    isRtl: false,
  });
  const [result, setResult] = React.useState<any[]>([]);
  const [page, setpage] = React.useState(1);
  const cardsWrapperRef = React.useRef<HTMLDivElement | null>(null);
  const [loading, setloading] = React.useState(true);
  const [totalCount, settotalCount] = React.useState(0);
  const [scrollCurrentItem, setScrollCurrentItem] = React.useState(0);
  const globalFilterReducers = useSelector(
    (state: {
      GlobalFilterReducers: {
        globalFilter: GetRecommendedTourCitiesPublicFilterBy;
        error: any;
      };
    }) => state.GlobalFilterReducers
  );
  const GetAllToursBySectionIdPublicAPI = React.useCallback(
    async (id, pageindex, pagesize) => {
      setloading(true);
      const res = await GetAllToursBySectionIdPublic(
        id,
        pageindex,
        pagesize,
        globalFilterReducers.globalFilter
      );
      setResult((res && (res as any).result) || []);
      settotalCount((res && (res as any).totalCount) || 0);
      setloading(false);
    },
    [globalFilterReducers.globalFilter]
  );
  const getIsScrollable = React.useCallback(
    (direction: 'previous' | 'next'): boolean => {
      if (!cardsWrapperRef.current) return false;
      const isRtl: boolean = language.isRtl;
      const element: HTMLDivElement = (cardsWrapperRef.current as HTMLDivElement)
        .firstChild as HTMLDivElement;
      const maxScrollLeft: number = Math.round(element.scrollWidth - element.clientWidth);
      if (
        (direction === 'previous' &&
          ((!isRtl && (element.scrollLeft <= 0 || scrollCurrentItem <= 0)) ||
            (isRtl &&
              (Math.abs(element.scrollLeft) >= maxScrollLeft || scrollCurrentItem <= 0)))) ||
        (direction === 'next' &&
          ((!isRtl &&
            (element.scrollLeft >= maxScrollLeft || scrollCurrentItem >= result.length - 1)) ||
            (isRtl &&
              (Math.abs(element.scrollLeft) >= maxScrollLeft ||
                scrollCurrentItem >= result.length - 1))))
      )
        return false;
      return true;
    },
    [language, result.length, scrollCurrentItem]
  );
  const next = () => {
    if (totalCount <= page * 3) {
      setpage(page);
    } else {
      setpage(page + 1);
    }
  };
  const back = () => {
    if (page === 0) {
      setpage(0);
    } else {
      setpage(page - 1);
    }
  };
  const toHandler = (direction: 'previous' | 'next') => () => {
    const element: HTMLDivElement = (cardsWrapperRef.current as HTMLDivElement)
      .firstChild as HTMLDivElement;
    if (!element) return;
    if (!getIsScrollable(direction)) {
      setScrollCurrentItem(() => {
        element.scrollTo({
          left: (element.childNodes[0] as HTMLDivElement).offsetLeft,
          behavior: 'smooth',
        });
        return 0;
      });
      if (direction === 'next') next();
      else back();
      return;
    }
    setScrollCurrentItem((item) => {
      if (direction === 'next') item += 1;
      else item -= 1;
      element.scrollTo({
        left: (element.childNodes[item] as HTMLDivElement).offsetLeft,
        behavior: 'smooth',
      });
      return item;
    });
  };
  React.useEffect(() => {
    GetAllToursBySectionIdPublicAPI(props.res.applicationSectionId, page, 3);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.res.applicationSectionId, page]);

  return (
    <div className="BestTours-Seaction w-100 childs-wrapper">
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className="tiltes-Seaction-continer w-100"
      >
        <Grid className="main-title">{props.res.name}</Grid>
        <Grid className="sub-title-1">{props.res.description}</Grid>
      </Grid>
      <div className="w-100 d-flex-center flex-wrap mb-2">
        <Button
          className="MuiButtonBase-root btns-icons mx-2 mb-2"
          type="button"
          onClick={toHandler('previous')}
          disabled={page === 1}
        >
          <span className="mdi mdi-chevron-left"></span>
        </Button>
        <Button
          className="MuiButtonBase-root btns-icons mx-2 mb-2"
          onClick={toHandler('next')}
          disabled={totalCount <= page * 3}
        >
          <span className="mdi mdi-chevron-right"></span>
          <span className="MuiTouchRipple-root"></span>
        </Button>
      </div>
      <div className="flex-container" ref={cardsWrapperRef}>
        <RectangleBestTours Data={result} loading={loading} />
      </div>
    </div>
  );
};
