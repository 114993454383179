import React, { useCallback, useEffect, useState } from 'react';
import { DateFunc, DateRangePicker, OnChangeProps, Range } from 'react-date-range';
import moment from 'moment';
import { useEventListener, useLocalStorage } from '../../Hooks';
import { DropdownUiComponent } from '../Dropdown/Dropdown.UiComponent';
interface DateRangePickerUiComponentProps {
  ranges: Range[];
  onDateChanged?: (range: OnChangeProps) => void;
  minDate?: string | Date | DateFunc;
  maxDate?: string | Date | DateFunc;
  disabledDates?: Date[];
  wrapperClasses?: string;
  popoverIdRef?: string;
  translationPath?: string;
  parentTranslationPath?: string;
  displayFormat?: string;
  emptyLabel?: string;
  idRef?: string;
  labelValue?: string;
  labelClasses?: string;
  dateRangeClasses?: string;
  themeClass?: 'theme-dark';
  isDisabled?: boolean;
  startDateError?: boolean;
  endDateError?: boolean;
  helperText?: string;
  isSubmitted?: boolean;
  withBackdrop?: boolean;
}
export const DateRangePickerUiComponent = ({
  ranges,
  onDateChanged,
  minDate,
  maxDate,
  popoverIdRef = 'dateRangePopoverRef',
  translationPath = '',
  parentTranslationPath = '',
  displayFormat = 'DD/MM/YYYY',
  emptyLabel = 'all',
  idRef = 'dateRangePickerRef',
  labelValue,
  labelClasses = '',
  wrapperClasses = '',
  dateRangeClasses = '',
  themeClass,
  isDisabled,
  startDateError,
  endDateError,
  helperText,
  isSubmitted,
  disabledDates,
  withBackdrop,
}: DateRangePickerUiComponentProps) => {
  const [language] = useLocalStorage<{ currentLanguage: string; isRtl: boolean }>('localization', {
    currentLanguage: 'en',
    isRtl: false,
  });
  const [pickerDirection, setPickerDirection] = useState<string>();
  const getPickerDirection = useCallback(() => {
    if (window.innerWidth <= 991.98 && pickerDirection !== 'vertical')
      setPickerDirection('vertical');
    else if (window.innerWidth > 991.98 && pickerDirection !== 'horizontal')
      setPickerDirection('horizontal');
  }, [pickerDirection]);
  useEventListener('resize', () => getPickerDirection());
  useEffect(() => {
    if (!pickerDirection) getPickerDirection();
  });
  return (
    <div className={`date-range-picker-wrapper ${wrapperClasses} ${themeClass || ''}`}>
      <DropdownUiComponent
        idRef={idRef}
        popoverIdRef={popoverIdRef}
        labelValue={labelValue}
        wrapperClasses={wrapperClasses}
        labelClasses={labelClasses}
        emptyLabel={emptyLabel}
        helperText={helperText}
        isSubmitted={isSubmitted}
        isDisabled={isDisabled}
        hasError={startDateError || endDateError}
        titleText="date"
        titleIcon="mdi mdi-calendar"
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        themeClass={themeClass}
        withBackdrop={withBackdrop}
        contentComponent={
          (ranges &&
            ranges.length > 0 &&
            ranges[0] &&
            ranges[0].startDate &&
            ranges[0].endDate &&
            ((isBlurOrChanged) => (
              <>
                <span
                  className={
                    (startDateError && (isBlurOrChanged || isSubmitted) && 'start-date-error') ||
                    undefined
                  }
                >
                  {moment(ranges[0].startDate)
                    .local(language.currentLanguage)
                    .format(displayFormat)}
                </span>
                <span className="px-1">-</span>
                <span
                  className={
                    (endDateError && (isBlurOrChanged || isSubmitted) && 'end-date-error') ||
                    undefined
                  }
                >
                  {moment(ranges[0].endDate).local(language.currentLanguage).format(displayFormat)}
                </span>
              </>
            ))) ||
          undefined
        }
        popoverComponent={
          <DateRangePicker
            months={2}
            direction={pickerDirection}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            ranges={ranges}
            minDate={minDate}
            maxDate={maxDate}
            className={`date-range-picker ${dateRangeClasses} ${themeClass || ''}`}
            disabledDates={disabledDates}
            onChange={onDateChanged}
          />
        }
      />
    </div>
  );
};
