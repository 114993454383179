import * as React from 'react';
import Rating from '@material-ui/lab/Rating';
import Skeleton from '@material-ui/lab/Skeleton';

export const ToursRecommendedSkeleton = (props: any ) => {
  const numbers = [1, 2, 3];

  const listItems = numbers.map((number, index: number) => (
    <div className='card-border' key={index}>
      <div className='firstcard' >
        <Skeleton animation='wave' height='292px' width='350px' />
        <Skeleton animation='wave' height='25px' width='46%' />
        <Skeleton animation='wave' height='25px' width='27%' />
        <div className='text-small-rating'>
          <Rating name='read-only' size='small' value={0} readOnly />
          <Skeleton animation='wave' height='17px' width='12%' />
        </div>
        <Skeleton animation='wave' height='17px' width='12%' />
        <Skeleton animation='wave' height='25px' width='27%' />
      </div>
    </div>
  ));
  return (
    <div className='flex-container '>
      {listItems}
    </div>
  );
};
