import * as React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

export const SectionsSkeleton = (props: any) => {
  const numbers = [1];

  const listItems = numbers.map((number , index: number   ) => (
    <div key={index}>
        <Skeleton animation='wave' height='45rem' width='100%'  />
    </div>
  ));
  return <div className='flex-container '>{listItems}</div>;
};
