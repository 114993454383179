import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../../Helpers/HttpMethod.Helper';

export const GetAllOffersPublic = async (pageIndex: number, pageSize: number, payload: any) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/Offers/GetAllOffersPublic/${config.applicationId}/${config.organizationsId}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      payload,
      { headers: { isPublic: true } }
    );
    return result;
  } catch (e) {
    return;
  }
};

export const GetAllToursByOfferId = async (
  offerId: any,
  pageIndex: number,
  pageSize: number,
  payload: any
) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/Offers/GetAllToursByOfferIdPublic/${offerId}/${config.applicationId}/${config.organizationsId}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      payload,
      { headers: { isPublic: true } }
    );
    return result;
  } catch (e) {
    return;
  }
};

export const GetDefaultCarTypeForPublic = async () => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/CarTypes/GetDefaultCarTypeForPublic/${config.organizationsId}/${config.applicationId}`,
      { headers: { isPublic: true } }
    );
    return result;
  } catch (e) {
    return;
  }
};
