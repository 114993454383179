import React, { useState } from 'react';
import { FormControl, TextField, CircularProgress, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '../../Hooks';
interface InputsUiComponantProps {
  idRef: string;
  value?: any;
  isLoading?: boolean;
  multiple?: boolean;
  autoFocus?: boolean;
  onKeyUp?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  translationPath?: string;
  parentTranslationPath?: string;
  labelValue?: string;
  inputPlaceholder?: string;
  wrapperClasses?: string;
  autocompleteClasses?: string;
  variant?: 'outlined' | 'standard' | 'filled';
  inputWrapperClasses?: string;
  inputClasses?: string;
  helperText?: string;
  inputLabel?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  error?: boolean;
  isWithError?: boolean;
  isSubmitted?: boolean;
  // PropTypes.oneOfType([PropTypes.elementType, Function, PropTypes.node])
  overInputIcon?: string;
  buttonOptions?: {
    className: string;
    iconClasses: string;
    onActionClicked?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isDisabled: boolean;
  };
  autoCompleteParams?: any;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  onInputChanged?: Function;
  onInputBlur?: Function;
  withLoader?: boolean;
  multiline?: boolean;
  fieldClasses?: string;
  beforeIconClasses?: string;
  afterIconClasses?: string;
  labelClasses?: string;
  themeClass?: 'theme-dark' | 'theme-default-dark';
  label?: string;
  type?: string;
  max?: string;
  maxLength?: string;
  min?: string;
  minLength?: string;
  step?: string;
  rows?: number;
  overInputText?: string;
  refs?: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null;
  paddingReverse?: string | number;
}
export const InputsUiComponent = ({
  value,
  isRequired,
  isDisabled,
  idRef,
  onInputChanged,
  fieldClasses = '',
  wrapperClasses = '',
  labelClasses = '',
  themeClass,
  translationPath = '',
  parentTranslationPath = '',
  labelValue,
  error,
  helperText = '',
  withLoader,
  autoCompleteParams = {},
  isLoading,
  variant = 'standard',
  label,
  inputPlaceholder,
  rows = 4,
  autoFocus,
  multiline,
  type,
  onInputBlur,
  onKeyUp,
  onKeyDown,
  buttonOptions,
  max,
  maxLength,
  min,
  minLength,
  step,
  endAdornment,
  startAdornment,
  beforeIconClasses,
  afterIconClasses,
  multiple,
  refs,
  isWithError,
  isSubmitted,
  overInputText,
  overInputIcon,
  paddingReverse,
}: InputsUiComponantProps): React.ReactElement => {
  const [isBlurOrChanged, setIsBlurOrChanged] = useState(false);
  const { t } = useTranslation(parentTranslationPath);
  const [language] = useLocalStorage<{ currentLanguage: string; isRtl: boolean }>('localization', {
    currentLanguage: 'en',
    isRtl: false,
  });
  return (
    <FormControl
      className={`input-wrapper ${wrapperClasses}${
        (startAdornment && ' with-start-andorment') || ''
      } ${themeClass || ''}`}
    >
      {labelValue && (
        <label
          htmlFor={idRef}
          className={`label-wrapper ${labelClasses}${isDisabled ? ' disabled' : ''}`}
        >
          {t(`${translationPath}${labelValue}`)}
        </label>
      )}
      <div className="d-flex-center w-100 p-relative">
        {beforeIconClasses && (
          <span className={`before-icon-classes-wrapper ${beforeIconClasses}`} />
        )}
        {(overInputText || overInputIcon) && (
          <span className="over-input-wrapper">
            {overInputIcon && <span className={overInputIcon} />}
            {overInputText && t(`${translationPath}${overInputText}`)}
          </span>
        )}
        <div className="text-field-wrapper">
          <TextField
            // {...renderParams}
            {...autoCompleteParams}
            ref={refs}
            required={isRequired}
            disabled={isDisabled}
            className={`inputs ${fieldClasses}`}
            style={
              (language.isRtl && {
                paddingRight: paddingReverse,
              }) || {
                paddingLeft: paddingReverse,
              }
            }
            id={idRef}
            label={label && t(`${translationPath}${label}`)}
            placeholder={inputPlaceholder && t(`${translationPath}${inputPlaceholder}`)}
            variant={variant}
            helperText={
              helperText &&
              ((isWithError && (isBlurOrChanged || isSubmitted) && error && helperText) ||
                (!isWithError && t(`${translationPath}${helperText}`)))
            }
            value={value}
            error={
              (isWithError && (isBlurOrChanged || isSubmitted) && error) ||
              (!isWithError && !isBlurOrChanged && error)
            }
            rows={rows}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            type={type}
            autoFocus={autoFocus}
            multiline={multiline}
            onChange={
              ((onInputChanged || isWithError) &&
                ((event) => {
                  if (!isBlurOrChanged) setIsBlurOrChanged(true);
                  if (onInputChanged) onInputChanged(event);
                })) ||
              undefined
            }
            onBlur={(event) => {
              setIsBlurOrChanged(true);
              if (onInputBlur) onInputBlur(event);
            }}
            inputProps={{
              max,
              maxLength,
              min,
              minLength,
              step,
              multiple,
              ...autoCompleteParams.inputProps,
            }}
            InputProps={{
              endAdornment:
                (withLoader && !endAdornment && (
                  <>{isLoading ? <CircularProgress color="inherit" size={20} /> : null}</>
                )) ||
                endAdornment,
              ...autoCompleteParams.InputProps,
              startAdornment:
                startAdornment ||
                (autoCompleteParams.InputProps && autoCompleteParams.InputProps.startAdornment) ||
                undefined,
            }}
          />
          {afterIconClasses && (
            <span className={`after-icon-classes-wrapper ${afterIconClasses}`} />
          )}
        </div>
        {buttonOptions && (
          <ButtonBase
            className={`${buttonOptions.className}`}
            onClick={buttonOptions.onActionClicked}
            disabled={buttonOptions.isDisabled}
          >
            <span className={buttonOptions.iconClasses} />
          </ButtonBase>
        )}
      </div>
    </FormControl>
  );
};
