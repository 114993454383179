import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../../Helpers/HttpMethod.Helper';

export const GetInTouch = async (payload:any) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/Identity/Newsletter/AddSubscription/${config.organizationsId}/${config.applicationId}`,
      payload
    );
    return result;
  } catch (e) {
    return;
  }
};
