import React, { ChangeEvent, FormEvent, useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutocompleteUiComponent,
  InputsUiComponent,
  SpinnerUiComponent,
} from '../../../UiComponentes';
import Joi from 'joi';
import { useTitle } from '../../../Hooks';
import { showError, getErrorByName, showSuccess, GetParams } from '../../../Helpers';
import 'react-phone-input-2/lib/style.css';
import { ButtonBase } from '@material-ui/core';
import config from '../../../Assets/Configration/Configration.json';
import { useDispatch, useSelector } from 'react-redux';
import { LoginActions } from '../../../Stores/Actions';
import { LoginResponseDto } from '../../../Services/Account';
import { useHistory } from 'react-router';
import { Errors, GetPublicLookupItem, SignupApplicationUserService } from '../../../Services';
// @ts-ignore
import { OldSocialLogin as SocialLogin } from 'react-social-login';
import TwitterLogin from 'react-twitter-login';
import { SocialMediaProviderType } from '../../../Enums';
import { PhonesComponent } from '../../../UiComponentes/PhonesComponent/PhonesComponent';
const translationPath = '';
const parentTranslationPath = 'CreateAccount';
export const CreateAccountView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const [isView, setIsView] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  // const facebookRef = useRef<React.>(null)
  const loginResponse = useSelector(
    (state: { LoginReducers: { loginResponse: LoginResponseDto | Errors } }) =>
      state.LoginReducers.loginResponse
  );

  console.log('loginResponse: ', loginResponse);
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    else if (action.id === 'edit') {
      return {
        ...action.value,
      };
    }
  }, []);

  useTitle(t(`${translationPath}login`));
  const [state, setState] = useReducer(reducer, {
    identityVerificationId: +GetParams('id').split('?email=')[0],
    password: '',
    firstName: '',
    secondName: '',
    lastName: '',
    identity: '',
    phoneNumber: '',
    email: GetParams('id').split('?email=')[1] || '',
    profileImg: 'd58b7e45-843d-4832-af7d-f49dd83f51be',
    whatsAppNumber: '',
    nationalityId: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [stateCreateUser, setstateCreateUser] = useState(null);
  const schema = Joi.object({
    firstName: Joi.string()
      .required()
      .messages({
        'string.empty': t(`${translationPath}firstName-is-required`),
      }),
    lastName: Joi.string()
      .required()
      .messages({
        'string.empty': t(`${translationPath}lastName-is-required`),
      }),
    identity: Joi.string()
      .required()
      .messages({
        'string.empty': t(`${translationPath}userName-is-required`),
      }),
    phoneNumber: Joi.string()
      .required()
      .min(3)
      .messages({
        'string.base': t(`${translationPath}please-select-at-least-one-document`),
        'string.empty': t(`${translationPath}phoneNumber-is-required`),
      }),
    email: Joi.string()
      .required()
      .messages({
        'string.empty': t(`${translationPath}email-is-required`),
      }),
    password: Joi.string()
      .required()
      .messages({
        'string.empty': t(`${translationPath}password-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const loginHandler = async (): Promise<void> => {
    setIsLoading(true);
    await dispatch(LoginActions.login(stateCreateUser));
    setIsLoading(false);
  };
  const loginResponseHandler = useCallback(() => {
    if (loginResponse) {
      if ((loginResponse as LoginResponseDto).token) {
        localStorage.setItem('session', JSON.stringify(loginResponse));
        showSuccess(t(`${translationPath}login-successfully`));
        history.push('/');
      } else if ((loginResponse as Errors).data && (loginResponse as Errors).data.ErrorId)
        showError(t(`${translationPath}${(loginResponse as Errors).data.Message}`));
    }
  }, [history, loginResponse, t]);

  const [loadings, setloadings] = useState(false);
  console.log('loadings: ', loadings);
  const [Nationality, setNationality] = useState({});
  console.log('Nationality: ', Nationality);
  const lookupActivityRating = useCallback(async () => {
    setloadings(true);
    const result = await GetPublicLookupItem({
      lookupTypeName: 'Country', // Lookups.,
    });
    setloadings(false);
    setNationality((result && result.result) || []);
  }, []);

  const handleSocialLogin = (from: 'Google' | 'Facebook') => async (user: any, err: any) => {
    if (user) {
      setIsLoading(true);
      await dispatch(
        LoginActions.login({
          socialMediaProviderTypeId: SocialMediaProviderType[from],
          token: user._token.accessToken,
          privateToken: null,
          organizationId: state.organizationId,
          applicationId: state.applicationId,
        })
      );
      setIsLoading(false);
    }
  };
  const authHandler = async (err: any, data: any) => {
    if (data) {
      setIsLoading(true);
      await dispatch(
        LoginActions.login({
          socialMediaProviderTypeId: SocialMediaProviderType.Twitter,
          token: data.oauth_token,
          privateToken: data.oauth_token_secret,
          organizationId: state.organizationId,
          applicationId: state.applicationId,
        })
      );
      setIsLoading(false);
    }
  };
  const CreateApplicationUser = React.useCallback(async () => {
    setIsLoading(true);
    const response = await SignupApplicationUserService(state);
    if ((response as Errors).data && (response as Errors).data.ErrorId) {
      showError(t(`${translationPath}code-not-couurect`));
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setstateCreateUser({
        identity: state.email,
        password: state.password,
        organizationId: config.organizationsId,
        applicationId: config.applicationId,
      });
    }
  }, [state, t]);

  const saveHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (schema.error) {
      showError(t('please-fix-all-errors'));
      return;
    }

    CreateApplicationUser();

    //
  };
  useEffect(() => {
    loginResponseHandler();
  }, [loginResponse, loginResponseHandler]);

  useEffect(() => {
    lookupActivityRating();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stateCreateUser !== null) {
      loginHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCreateUser]);

  return (
    <div className='CreateAccount-wrapper views-wrapper'>
      <SpinnerUiComponent isActive={isLoading} />
      <div className='login-header'>
        <span className='title-text'>{t(`${translationPath}login-title`)}</span>
        <span>{t(`${translationPath}login-description`)}</span>
      </div>
      <form noValidate className='login-body' onSubmit={saveHandler}>
        <div className='body-item'>
          <InputsUiComponent
            idRef='firstNameRef'
            inputPlaceholder='firstName'
            value={state.firstName}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            startAdornment={
              <>
                {' '}
                <span className='mdi mdi-account' />
              </>
            }
            themeClass='theme-dark'
            isSubmitted={isSubmitted}
            isWithError
            helperText={getErrorByName(schema, 'firstName').message}
            error={getErrorByName(schema, 'firstName').error}
            onInputChanged={(event: ChangeEvent<HTMLInputElement>) => {
              setState({ id: 'firstName', value: event.target.value });
            }}
          />
          <InputsUiComponent
            idRef='lastNameRef'
            inputPlaceholder='lastName'
            value={state.lastName}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            startAdornment={<span className='mdi mdi-lastpass ' />}
            themeClass='theme-dark'
            isSubmitted={isSubmitted}
            isWithError
            helperText={getErrorByName(schema, 'lastName').message}
            error={getErrorByName(schema, 'lastName').error}
            onInputChanged={(event: ChangeEvent<HTMLInputElement>) => {
              setState({ id: 'lastName', value: event.target.value });
            }}
          />
        </div>
        <div className='body-item'>
          <InputsUiComponent
            idRef='userNameRef'
            inputPlaceholder='userName'
            value={state.identity}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            startAdornment={<span className='mdi mdi-account-edit' />}
            themeClass='theme-dark'
            isSubmitted={isSubmitted}
            isWithError
            helperText={getErrorByName(schema, 'identity').message}
            error={getErrorByName(schema, 'identity').error}
            onInputChanged={(event: ChangeEvent<HTMLInputElement>) => {
              setState({ id: 'identity', value: event.target.value });
            }}
          />
        </div>
        <div className='body-item'>
          <InputsUiComponent
            idRef='usernameRef'
            inputPlaceholder='Emailaddress'
            value={state.email}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            startAdornment={<span className='mdi mdi-email' />}
            themeClass='theme-dark'
            isSubmitted={isSubmitted}
            isWithError
            helperText={getErrorByName(schema, 'email').message}
            error={getErrorByName(schema, 'email').error}
            onInputChanged={(event: ChangeEvent<HTMLInputElement>) => {
              setState({ id: 'email', value: event.target.value });
            }}
          />
        </div>
        <div className='body-item'>
          <InputsUiComponent
            idRef='passwordRef'
            inputPlaceholder='password'
            value={state.password}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            startAdornment={<span className='mdi mdi-lock' />}
            endAdornment={
              <ButtonBase
                className='btns-icons theme-transparent theme-dark'
                onClick={() => setIsView((item) => !item)}>
                <span className='mdi mdi-eye-outline' />
              </ButtonBase>
            }
            themeClass='theme-default-dark'
            isSubmitted={isSubmitted}
            isWithError
            type={(isView && 'text') || 'password'}
            helperText={getErrorByName(schema, 'password').message}
            error={getErrorByName(schema, 'password').error}
            onInputChanged={(event: ChangeEvent<HTMLInputElement>) => {
              setState({ id: 'password', value: event.target.value });
            }}
          />
        </div>
        <div className='body-item'>
          <AutocompleteUiComponent
            idRef='NationalityRef'
            inputPlaceholder='Nationality'
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            inputStartAdornment={<span className='mdi mdi-passport' />}
            //getOptionSelected={(option) => option.key === 'zzzzzzz'}
            options={Nationality || []}
            isLoading={loadings}
            getOptionLabel={(option) => option.lookupItemName || ''}
            themeClass='theme-dark'
            withBackdrop
            onChange={(event, newValue) => {
              setState({ id: 'nationalityId', value: newValue && newValue.lookupItemId });
            }}
          />
        </div>
        <div className='body-item mt-4'>
          <PhonesComponent
            country='ae'
            idRef='phoneNumberRef'
            //  labelValue='whatsapp'
            translationPath={translationPath}
            value={state.phoneNumber}
            helperText={getErrorByName(schema, 'phoneNumber').message}
            error={getErrorByName(schema, 'phoneNumber').error}
            // buttonOptions={{
            //   className: 'btns',
            //   iconClasses: 'mdi mdi-passport',
            // }}

            inputPlaceholder='phoneNumber'
            onInputChanged={(value) => setState({ id: 'phoneNumber', value })}
          />{' '}
        </div>
        <div className='body-item mt-3'>
          <ButtonBase type='submit' className='btns'>
            <span>{t(`${translationPath}login`)}</span>
          </ButtonBase>
        </div>
        <div className='body-item'>
          <span>{t(`${translationPath}or-connect-with`)}</span>
        </div>
      </form>
      <div className='login-footer'>
        <div className='footer-item'>
          <TwitterLogin
            authCallback={authHandler}
            consumerKey='GwRJUTPvnLWPtwZ67u72euT4b'
            consumerSecret='YFcWaP1602RimnhftCZvxKHNSQhxsznYqwqhYXu0R8URD3cgYu'
            children={
              <ButtonBase className='btns theme-dark theme-transparent br-0'>
                <span className='mdi mdi-twitter' />
                <span className='px-1'>{t(`${translationPath}twitter`)}</span>
              </ButtonBase>
            }
          />
        </div>
        <div className='footer-item'>
          <SocialLogin
            provider='facebook'
            appId={config.facebookAppKey}
            callback={handleSocialLogin('Facebook')}>
            <ButtonBase className='btns theme-dark theme-transparent br-0'>
              <span className='mdi mdi-facebook' />
              <span className='px-1'>{t(`${translationPath}facebook`)}</span>
            </ButtonBase>
          </SocialLogin>
        </div>
        <div className='footer-item'>
          <SocialLogin
            provider='google'
            appId={config.googleAppKey}
            callback={handleSocialLogin('Google')}>
            <ButtonBase className='btns theme-dark theme-transparent br-0'>
              <span className='mdi mdi-google' />
              <span className='px-1'>{t(`${translationPath}google`)}</span>
            </ButtonBase>
          </SocialLogin>
        </div>
      </div>
      <div className='outside-login'>
        <div className='outside-item'>
          <span>{t(`${translationPath}register-description`)}</span>
          <ButtonBase
            className='btns theme-dark theme-transparent'
            onClick={() => history.push('/accounts/RegisterAccount')}>
            <span className='opacity-1'>{t(`${translationPath}register-now`)}</span>
          </ButtonBase>
        </div>
      </div>
    </div>
  );
};
