import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../../Helpers/HttpMethod.Helper';
import {
  GetRecommendedTourCitiesPublicDto,
  GetRecommendedTourCitiesPublicFilterBy,
} from './Interfaces/Tours.Interface';

export const GetRecommendedTourCitiesPublic = async (
  pageIndex: number,
  pageSize: number,
  body: GetRecommendedTourCitiesPublicFilterBy
): Promise<GetRecommendedTourCitiesPublicDto | any> => {
  const result = await HttpServices.post(
    `${config.serverAddress}/PSTViewing/Tours/GetRecommendedTourCitiesPublic/${config.applicationId}/${config.organizationsId}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    body,
    { headers: { isPublic: true } }
  )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });

  return result;
};
