import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../../Helpers/HttpMethod.Helper';
import {
  GetAllMyFavoriteToursDto ,
  GetRecommendedTourCitiesPublicFilterBy,
} from './Interfaces/Tours.Interface';
export const GetAllMyFavoriteTours = async (
    pageIndex: number,
    pageSize: number,
    body: GetRecommendedTourCitiesPublicFilterBy
  ): Promise<GetAllMyFavoriteToursDto | any> => {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/Tours/GetAllMyFavoriteTours?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      body,
      { headers: { isPublic: false } }
    )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  
    return result;
  };

  export const GetTourById = async (
    tourId:number,
    body: GetRecommendedTourCitiesPublicFilterBy
  ): Promise<GetAllMyFavoriteToursDto | any> => {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/Tours/GetTourById/${tourId}`,
      body,
      { headers: { isPublic: false } }
    )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  
    return result;
  };

  export const AddFavoriteTour = async (tourId:object)=> {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/Tours/AddFavoriteTour`,tourId,
      { headers: { isPublic: false } }
    )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  
    return result;
  };
  export const RemoveFavoriteTour = async (tourId:number)=> {
    const result = await HttpServices.delete(
      `${config.serverAddress}/PSTViewing/Tours/RemoveFavoriteTour/${tourId}`,
      { headers: { isPublic: false } }
    )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  
    return result;
  };
  