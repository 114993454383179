import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../../Helpers/HttpMethod.Helper';

export const GetAllActiveCities = async (pageIndex:any,pageSize:any ) => {
    try {
      const result = await HttpServices.get(
        `${config.serverAddress}/PSTViewing/City/GetAllActiveCities?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      );
      return result;
    } catch (e) {
      return;
    }
  };
  export const GetAllActiveCitiesPublic = async (pageIndex:any,pageSize:any ) => {
    try {
      const result = await HttpServices.get(
        `${config.serverAddress}/PSTViewing/City/GetAllActiveCitiesPublic/${config.applicationId}/${config.organizationsId}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
        );
      return result;
    } catch (e) {
      return;
    }
  };
