import { GlobalFilterActions } from '../../Actions';
import { GlobalFilterStates } from '../../States';
import { takeEvery ,put } from 'redux-saga/effects';

function* fetchUpdate(action: any) {
   try {
     yield put(GlobalFilterActions.updateSuccess(action.payload));
        localStorage.setItem('globalFilter',JSON.stringify(action.payload))
   } catch (err) {
     yield put(GlobalFilterActions.updateFailed(err));
   }
}
export function* watchGlobalFilterUpdate() {
  yield takeEvery(GlobalFilterStates.UPDATE, fetchUpdate);
}
