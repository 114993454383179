import { toast } from 'react-toastify';

function showSuccess(message:string, configuration:any = { autoClose: 3000 }) {
    toast.success(message, configuration);
}

function showError(message:string, configuration:any = { autoClose: 3000 }) {
    toast.error(message, configuration);
}

function showWarn(message:string, configuration:any = { autoClose: 4000 }) {
    toast.warn(message, configuration);
}

function showInfo(message:string, configuration:any = { autoClose: 3000 }) {
    toast.info(message, configuration);
}

export {
    showSuccess, showError, showWarn, showInfo
};
