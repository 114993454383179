import * as React from 'react';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import { Button } from '@material-ui/core';
import { GetAllToursBySectionIdPublic } from '../../../../../../../../Services/WebsiteSections/WebsiteSections.Service';
import Grid from '@material-ui/core/Grid';
import { ToursRecommendedPointUiComponent } from '../../../../../../../../UiComponentes/ToursRecommendedPoint/ToursRecommendedPoint.UiComponent';
import { ToursRecommendedSkeleton } from './SectionsUtilties/Tours.Recommended.Skeleton';
import { useLocalStorage } from '../../../../../../../../Hooks';
import { GetRecommendedTourCitiesPublicFilterBy } from '../../../../../../../../Services';
import { useSelector } from 'react-redux';
export const ToursRecommendedView = (props: any) => {
  const [result, setResult] = React.useState<any[]>([]);
  // const [value, setValue] = React.useState(1);
  // const handleChange = (event: any, newValue: number) => {
  //   setValue(newValue);
  // };
  const globalFilterReducers = useSelector(
    (state: {
      GlobalFilterReducers: {
        globalFilter: GetRecommendedTourCitiesPublicFilterBy;
        error: any;
      };
    }) => state.GlobalFilterReducers
  );
  const [page, setpage] = React.useState(0);
  const [totalCount, settotalCount] = React.useState(0);
  const [loading, setloading] = React.useState(true);
  const cardsWrapperRef = React.useRef<HTMLDivElement | null>(null);
  const [scrollCurrentItem, setScrollCurrentItem] = React.useState(0);
  const [language] = useLocalStorage<{ currentLanguage: string; isRtl: boolean }>('localization', {
    currentLanguage: 'en',
    isRtl: false,
  });
  const GetAllToursBySectionIdPublicAPI = React.useCallback(
    async (id, pagesize, pageindex) => {
      setloading(true);
      const res = await GetAllToursBySectionIdPublic(
        id,
        pagesize,
        pageindex,
        globalFilterReducers.globalFilter
      );
      setResult((res && (res as any).result) || []);
      settotalCount((res && (res as any).totalCount) || 0);
      setloading(false);
    },
    [globalFilterReducers.globalFilter]
  );

  React.useEffect(() => {
    GetAllToursBySectionIdPublicAPI(props.res.applicationSectionId, page, 6);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.res.applicationSectionId, page]);
  const next = () => {
    if (totalCount <= page * 6) {
      setpage(page);
    } else {
      setpage(page + 1);
    }
  };
  const back = () => {
    if (page === 0) {
      setpage(0);
    } else {
      setpage(page - 1);
    }
  };

  const getIsScrollable = React.useCallback(
    (direction: string): boolean => {
      if (!cardsWrapperRef.current) return false;
      const isRtl: boolean = language.isRtl;
      const element: HTMLDivElement = cardsWrapperRef.current as HTMLDivElement;
      const maxScrollLeft: number = Math.round(element.scrollWidth - element.clientWidth);
      if (
        (direction === 'previous' &&
          ((!isRtl && (element.scrollLeft <= 0 || scrollCurrentItem <= 0)) ||
            (isRtl &&
              (Math.abs(element.scrollLeft) >= maxScrollLeft || scrollCurrentItem <= 0)))) ||
        (direction === 'next' &&
          ((!isRtl &&
            (element.scrollLeft >= maxScrollLeft || scrollCurrentItem >= result.length - 1)) ||
            (isRtl &&
              (Math.abs(element.scrollLeft) >= maxScrollLeft ||
                scrollCurrentItem >= result.length - 1))))
      )
        return false;
      return true;
    },
    [language.isRtl, result.length, scrollCurrentItem]
  );

  const toHandler = (direction: 'previous' | 'next') => () => {
    const element: HTMLDivElement = (cardsWrapperRef.current as HTMLDivElement) as HTMLDivElement;
    if (!element) return;
    if (!getIsScrollable(direction)) {
      setScrollCurrentItem(() => {
        element.scrollTo({
          left: (element.childNodes[0] as HTMLDivElement).offsetLeft,
          behavior: 'smooth',
        });
        return 0;
      });
      if (direction === 'next') next();
      else back();
      return;
    }
    setScrollCurrentItem((item) => {
      if (direction === 'next') item += 1;
      else item -= 1;
      element.scrollTo({
        left: (element.childNodes[item] as HTMLDivElement).offsetLeft,
        behavior: 'smooth',
      });
      return item;
    });
  };
  return (
    <div className="childs-wrapper">
      <div className="seaction-continer">
        <div className="Seaction-Titel-contenar">
          <div className="seaction-titel-main">{props.res.name}</div>
          <div className="sub-title-1">{props.res.description}</div>
        </div>
        <Grid container direction="column">
          <Grid className="Arrow-Contenar">
            <div className="bbt-Contenar">
              <Button
                disabled={page === 0 ? true : false}
                className="MuiButtonBase-root btns-icons mx-2 mb-2"
                onClick={toHandler('previous')}
              >
                <span className="mdi mdi-chevron-left"></span>
              </Button>
              <Button
                disabled={totalCount <= page * 6 ? true : false}
                className="MuiButtonBase-root btns-icons mx-2 mb-2"
                onClick={toHandler('next')}
              >
                <span className="mdi mdi-chevron-right"></span>
                <span className="MuiTouchRipple-root"></span>
              </Button>
            </div>
          </Grid>
        </Grid>
        {(!loading && (
          <div className="flex-container-ToursRecommended" ref={cardsWrapperRef}>
            {result &&
              result.map((Data: any, index: number) => (
                <ToursRecommendedPointUiComponent Data={Data} key={index} />
              ))}
          </div>
        )) || <ToursRecommendedSkeleton CountOfCard={1} />}
      </div>
    </div>
  );
};
