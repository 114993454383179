import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../../Helpers/HttpMethod.Helper';
import { GetDefaultCarTypeForPublicDto } from './Interfaces/CarTypes.Interface';
export const GetDefaultCarTypeForPublic = async (): Promise<
  GetDefaultCarTypeForPublicDto | any
> => {
  const result = await HttpServices.get(
    `${config.serverAddress}/PSTViewing/CarTypes/GetDefaultCarTypeForPublic/${config.organizationsId}/${config.applicationId}`,
    { headers: { isPublic: true } }
  )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });

  return result;
};
