import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { InputsUiComponent, SpinnerUiComponent } from '../../../UiComponentes';
import { useTitle } from '../../../Hooks';
import { GetParams, showError, showInfo } from '../../../Helpers';
import { ButtonBase } from '@material-ui/core';
import config from '../../../Assets/Configration/Configration.json';

import { useHistory } from 'react-router';
import {
  Errors,
  VerificationApplicationUserService,
  VerifyApplicationUserCodeService,
} from '../../../Services';

const translationPath = '';
const parentTranslationPath = 'VerificationCode';
export const VerificationCodeView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const history = useHistory();

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    else if (action.id === 'edit') {
      return {
        ...action.value,
      };
    }
  }, []);
  useTitle(t(`${translationPath}login`));
  const [state, setState] = useReducer(reducer, {
    organizationId: config.organizationsId,
    applicationId: config.applicationId,
    Number1: '',
    Number2: '',
    Number3: '',
    Number4: '',
    id: '',
    email: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const default_time = 60;
  const STATUS = {
    STARTED: 'Started',
    STOPPED: 'Stopped',
  };
  const [secondsRemaining, setSecondsRemaining] = useState(default_time);
  const [status, setStatus] = useState(STATUS.STOPPED);

  const saveHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitted(true);
    VerificationApplicationUser();
  };

  const PastHandler = async () => {
    navigator.clipboard
      .readText()
      .then((text) => {
        setState({ id: 'Number1', value: text.substr(0, 1) });
        setState({ id: 'Number2', value: text.substr(1, 1) });
        setState({ id: 'Number3', value: text.substr(2, 1) });
        setState({ id: 'Number4', value: text.substr(3, 1) });
      })
      .catch((err) => {
        showError(t(`${translationPath}Clipboardreadaccessdenied`), err);
      });
  };

  const handleStart = () => {
    setStatus(STATUS.STARTED);
  };

  function useInterval(callback: any, delay: any) {
    const savedCallback = useRef();
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        setStatus(STATUS.STOPPED);
      }
    },
    status === STATUS.STARTED ? 1000 : null
    // passing null stops the interval
  );

  const VerificationApplicationUser = React.useCallback(async () => {
    setIsLoading(true);
    const response = await VerifyApplicationUserCodeService({
      identityVerificationId: +state.id,
      code: state.Number1 + state.Number2 + state.Number3 + state.Number4,
    });
    if ((response as Errors).data && (response as Errors).data.ErrorId) {
      showError(t(`${translationPath}code-not-couurect`));
      setIsLoading(false);
    } else {
      setIsLoading(false);
      history.push(`/accounts/CreateAccount?id=${response.identityVerificationId}?email=${state.email}`);
    }
  }, [history, state, t]);

  const ReSend = React.useCallback(async () => {
    const response = await VerificationApplicationUserService({
      identity: GetParams('id').split('?Email=')[1],
      organizationsId: config.organizationsId,
      applicationsId: config.applicationId,
    });
    if ((response as Errors).data && (response as Errors).data.ErrorId) {
      showError(t(`${translationPath}failed`));
    } else {
      showInfo(t(`${translationPath}resend-code-successfully`));
      setState({ id: 'id', value: response.identityVerificationId });
      setState({ id: 'Number1', value: '' });
      setState({ id: 'Number2', value: '' });
      setState({ id: 'Number3', value: '' });
      setState({ id: 'Number4', value: '' });
    }
  }, [t]);

  useEffect(() => {
    if (secondsRemaining === 0) {
      setSecondsRemaining(default_time);
      setStatus(STATUS.STOPPED);
    }
  }, [STATUS.STOPPED, secondsRemaining]);

  useEffect(() => {
    const id = GetParams('id').split('?Email=')[0];
    setState({ id: 'id', value: id });
    const email = GetParams('id').split('?Email=')[1];
    setState({ id: 'email', value: email });
  }, []);

  return (
    <div className='VerificationCode-wrapper views-wrapper'>
      <SpinnerUiComponent isActive={isLoading} />
      <div className='d-flex-v-center px-1'>
        <div className='login-header'>
          <span className='title-text'>{t(`${translationPath}Entercode`)}</span>

          <span>
            {t(`${translationPath}Code-received`)} {state.email}
          </span>
        </div>
        <div className='close-bbt'>
          <ButtonBase
            className='btns-icon theme-solid mx-2 mb-2'
            onClick={() => history.push('/accounts/RegisterAccount')}>
            <span className='mdi mdi-close mdi-36px' />
          </ButtonBase>
        </div>
      </div>
      <form noValidate className='login-body' onSubmit={saveHandler}>
        <div className='close-bbt'>
          <ButtonBase
            className='btns-icon theme-solid mx-2 mb-2'
            // disabled={scrollCurrentItem >= data.length - 1}
            title={t(`${translationPath}past`)}
            onClick={() => PastHandler()}>
            <span className='mdi mdi-content-paste'></span>
          </ButtonBase>
        </div>
        <div className='body-item-number-input input-number'>
          <InputsUiComponent
            idRef='Number1Ref'
            value={state.Number1}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            themeClass='theme-dark'
            autoFocus
            maxLength={'1'}
            isSubmitted={isSubmitted}
            isWithError
            onInputChanged={(event: ChangeEvent<HTMLInputElement>) => {
              setState({ id: 'Number1', value: event.target.value });
            }}
          />
          <InputsUiComponent
            idRef='Number2Ref'
            value={state.Number2}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            themeClass='theme-dark'
            maxLength={'1'}
            isSubmitted={isSubmitted}
            isWithError
            onInputChanged={(event: ChangeEvent<HTMLInputElement>) => {
              setState({ id: 'Number2', value: event.target.value });
            }}
          />
          <InputsUiComponent
            idRef='Number3Ref'
            value={state.Number3}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            themeClass='theme-dark'
            maxLength={'1'}
            isSubmitted={isSubmitted}
            isWithError
            onInputChanged={(event: ChangeEvent<HTMLInputElement>) => {
              setState({ id: 'Number3', value: event.target.value });
            }}
          />
          <InputsUiComponent
            idRef='Number4Ref'
            value={state.Number4}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            themeClass='theme-dark'
            maxLength={'1'}
            isSubmitted={isSubmitted}
            isWithError
            onInputChanged={(event: ChangeEvent<HTMLInputElement>) => {
              setState({ id: 'Number4', value: event.target.value });
            }}
          />
        </div>
        <div className='body-item mt-4'>
          <ButtonBase type='submit' className='btns'>
            <span>{t(`${translationPath}Continue`)}</span>
          </ButtonBase>
        </div>
        <div className='d-flex-center'>
          <span></span>{' '}
        </div>
        <div className='body-item mt-2'>
          <ButtonBase
            className='btns theme-dark theme-transparent'
            disabled={secondsRemaining !== default_time}
            onClick={() => {
              ReSend();
              handleStart();
            }}>
            <span className='opacity-1'>
              {t(`${translationPath}Resend`)} {status === STATUS.STARTED ? secondsRemaining : ''}
            </span>
          </ButtonBase>
        </div>
      </form>
    </div>
  );
};
