import React from 'react';
import {
    OnBasketView
} from './BasketView/OnBasket.View';

const FavoriteLayoutViews = (): React.ReactElement => {
  return (
    <div >
      <OnBasketView/>
    </div>
  );
};

export default FavoriteLayoutViews;
