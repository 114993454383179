import { ButtonBase } from '@material-ui/core';
import React, { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CarSearchDto,
  GetDefaultCarTypeForPublicDto,
  GetRecommendedTourCitiesPublicFilterBy,
} from '../../../../../../../../Services';
import {
  AutocompleteUiComponent,
  CheckboxesUiComponent,
} from '../../../../../../../../UiComponentes';
interface CarsViewProps {
  filterBy: GetRecommendedTourCitiesPublicFilterBy;
  defaultCar?: GetDefaultCarTypeForPublicDto;
  onFilterByChanged: (FilterByNewValue: GetRecommendedTourCitiesPublicFilterBy) => void;
  translationPath: string;
  parentTranslationPath: string;
}
export const CarsView = ({
  defaultCar,
  filterBy,
  onFilterByChanged,
  translationPath,
  parentTranslationPath,
}: CarsViewProps): React.ReactElement => {
  const { t } = useTranslation(parentTranslationPath);
  const carTypesNumberChangedHandler = (value: 'push' | 'splice') => () => {};
  const getTotalHandler = (): number => {
    return filterBy.carSearch.reduce((total, item) => {
      total += item.adults.length || 0;
      total += item.childrens.length || 0;
      total += item.infants.length || 0;
      return total;
    }, 0);
  };
  const onSelectedCheckboxChanged = useCallback(
    (key: keyof CarSearchDto, carIndex: number, passengarIndex: number) => (
      event: ChangeEvent<{}>,
      checked: boolean
    ) => {},
    []
  );
  return (
    <div className="car-dialog-wrapper childs-wrapper">
      <div className="header-wrapper">
        <span>{t(`${translationPath}cars-title`)}</span>
      </div>
      <div className="body-wrapper">
        <div className="item-wrapper">
          <div className="item-body">
            <ButtonBase
              className="btns-icons theme-outline"
              disabled={!filterBy.carSearch || filterBy.carSearch.length === 0}
              onClick={carTypesNumberChangedHandler('splice')}
            >
              <span className="mdi mdi-minus" />
            </ButtonBase>
            <div className="details-wrapper">
              <div>
                <span>{filterBy.carSearch.length}</span>
                <span className="px-1">{t(`${translationPath}cars`)}</span>
              </div>
            </div>
            <ButtonBase
              className="btns-icons theme-outline"
              onClick={carTypesNumberChangedHandler('push')}
            >
              <span className="mdi mdi-plus" />
            </ButtonBase>
          </div>
          <div className="w-100">
            <span className="description-text">{t(`${translationPath}cars-description`)}</span>
          </div>
        </div>
        {getTotalHandler() > 0 && (
          <div className="items-wrapper">
            {filterBy.carSearch.map((item, index) => (
              <div className="item-wrapper">
                <AutocompleteUiComponent
                  idRef={`${index}`}
                  value={defaultCar}
                  options={[defaultCar]}
                  inputWrapperClasses="theme-transparent"
                  themeClass="theme-dark"
                  labelValue={`${t(`${translationPath}car`)} ${index + 1} ${t(
                    `${translationPath}type`
                  )}`}
                  getOptionLabel={(option) => option.typeName || ''}
                  onChange={(event, newValue) => {}}
                />
                <div className="item-checkboxes-wrapper">
                  {filterBy.carSearch.map((item, checkboxIndex) =>
                    item.adults.map((subItem, subIndex) => (
                      <CheckboxesUiComponent
                        idRef={`adultsNameRef${index}${checkboxIndex}${subIndex}`}
                        themeClass="theme-dark"
                        checked={true}
                        label={subItem}
                        onSelectedCheckboxChanged={onSelectedCheckboxChanged(
                          'adults',
                          checkboxIndex,
                          subIndex
                        )}
                      />
                    ))
                  )}
                  {filterBy.carSearch.map((item, checkboxIndex) =>
                    item.childrens.map((subItem, subIndex) => (
                      <CheckboxesUiComponent
                        idRef={`childrensNameRef${index}${checkboxIndex}${subIndex}`}
                        themeClass="theme-dark"
                        checked={true}
                        label={subItem}
                        onSelectedCheckboxChanged={onSelectedCheckboxChanged(
                          'childrens',
                          checkboxIndex,
                          subIndex
                        )}
                      />
                    ))
                  )}
                  {filterBy.carSearch.map((item, checkboxIndex) =>
                    item.infants.map((subItem, subIndex) => (
                      <CheckboxesUiComponent
                        idRef={`infantsNameRef${index}${checkboxIndex}${subIndex}`}
                        themeClass="theme-dark"
                        checked={true}
                        label={subItem}
                        onSelectedCheckboxChanged={onSelectedCheckboxChanged(
                          'infants',
                          checkboxIndex,
                          subIndex
                        )}
                      />
                    ))
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
