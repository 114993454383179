import React, { useState, useRef, useEffect } from 'react';
import logo from '../../../../Assets/Images/Defaults/psiLogo.png';
import menu from '../../../../Assets/Images/Icons/menu.png';
import cart from '../../../../Assets/Images/Icons/cart.png';
import usa from '../../../../Assets/Images/Countries/UnitedStatesOfAmerica.png';
import uae from '../../../../Assets/Images/Countries/UnitedArabEmirates.png';
import {
  AppBar,
  Button,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  SwipeableDrawer,
  Typography,
  Avatar,
  ButtonBase,
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { getDownloadablePrivateLink, languageChange } from '../../../../Helpers';
import { screenSize } from '../../../../Enums';
import { useLocalStorage, useElementSize } from '../../../../Hooks';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Errors, LoginResponseDto } from '../../../../Services';
import { PopoverUiComponent } from '../../../../UiComponentes';
import { UserMenuView } from './Dialogs';

export const HeaderView = () => {
  const { t } = useTranslation('Header');
  const history = useHistory();
  const [toggle, setToggle] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [currentLanguage] = useLocalStorage('localization', {});
  const [userMenuAttachedWith, setUserMenuAttachedWith] = useState<Element | null>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const headerSize = useElementSize(headerRef);
  const [lang, setLang] = useState(currentLanguage.currentLanguage);
  const loginResponse = useSelector(
    (state: { LoginReducers: { loginResponse: LoginResponseDto | Errors } }) =>
      state.LoginReducers.loginResponse
  );
  useEffect(() => {
    headerSize.width > screenSize.phonesMax ? setShowMenu(false) : setShowMenu(true);
  }, [headerSize]);

  const loginHandler = (): void => {
    history.push('accounts/login');
  };
  const allToursHandler =():void =>{
    history.push('AllTours');
  }
  const basketHandler =()=>{
    history.push('basket')
  }
  const favoriteHandler = (): void => {
    history.push('favorite');
  };
  const LandingHandler = (): void => {
    history.push('landing');
  };
  const handleClose = (): void => {
    setUserMenuAttachedWith(null);
  };
  const menuOpenHandler = (event: React.MouseEvent): void => {
    setUserMenuAttachedWith(event.currentTarget);
  };
  useEffect(() => {
    if (userMenuAttachedWith) {
      if (
        !loginResponse ||
        ((loginResponse as Errors).data && (loginResponse as Errors).data.ErrorId)
      ) {
        handleClose();
      }
    }
  }, [loginResponse, userMenuAttachedWith]);
  const list = () => (
    <div role="presentation" className="header-wrapper">
      <List>
        <ListItem button>
          <ListItemText primary={t('all-tours')} />
        </ListItem>
        <Divider />

        <ListItem button>
          <ListItemText primary={t('custome-tours')} />
        </ListItem>
        <Divider />

        <ListItem button>
          <ListItemText primary={t('favorites-tours')} />
        </ListItem>
        <Divider />

        <ListItem className="language-select">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>{t('language')}</AccordionSummary>
            <AccordionDetails>
              <Button
                onClick={() => {
                  setLang('ar');
                  languageChange('ar');
                }}
              >
                <img src={uae} alt="language" width="24px" />
                <Typography>{t('arabic')}</Typography>
              </Button>
              <Button
                onClick={() => {
                  setLang('en');
                  languageChange('en');
                }}
              >
                <img src={usa} alt="language" width="24px" />
                <Typography>{t('english')}</Typography>
              </Button>
            </AccordionDetails>
          </Accordion>
        </ListItem>
        <Divider />

        <ListItem button>
          <ListItemText primary={t('currency')} />
        </ListItem>
        <Divider />

        <ListItem button onClick={loginHandler}>
          <ListItemText primary={t('login')} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className="header-wrapper px-2" ref={headerRef}>
      <AppBar className="header-appbar">
        <Toolbar>
          <div className="mobile-logo">
            <div className="nav-cart">
              <IconButton onClick={() => setToggle(true)}>
                <img src={menu} alt="menu" width="24px" />
              </IconButton>
            </div>
            <div className="pst-logo">
              <img src={logo} alt="PST" onClick={LandingHandler} />
            </div>
            <div className="nav-cart">
              <IconButton> 
                <img src={cart} alt="cart" width="24px" />
              </IconButton>
            </div>
          </div>

          <div className="nav-items">
            <div className="tours-buttons">
              <div className="nav-item">
                <Button onClick={allToursHandler}>{t('all-tours')}</Button>
              </div>
              <div className="nav-item">
                <Button onClick={() => {}}>{t('custome-tours')}</Button>
              </div>
            </div>

            <div className="cart-login-section">
              <div className="custom-button">
                {((!loginResponse ||
                  ((loginResponse as Errors).data && (loginResponse as Errors).data.ErrorId)) &&
                  !userMenuAttachedWith && (
                    <Button onClick={loginHandler}>{t('login')}</Button>
                  )) || (
                  <ButtonBase
                    className="btns-icons theme-transparent w-40px h-40px"
                    onClick={menuOpenHandler}
                  >
                    {loginResponse && (
                      <Avatar
                        alt={(loginResponse as LoginResponseDto).fullName}
                        src={getDownloadablePrivateLink(
                          (loginResponse as LoginResponseDto).profileImg
                        )}
                      />
                    )}
                  </ButtonBase>
                )}
              </div>
              <PopoverUiComponent
                idRef="userMenuRef"
                handleClose={handleClose}
                attachedWith={userMenuAttachedWith}
                popoverClasses="mt-3"
                component={<UserMenuView />}
              />
              <div className="nav-button">
                <IconButton onClick={favoriteHandler}>
                  <span className="mdi mdi-heart-outline" />
                </IconButton>
              </div>
              <div className="nav-button cart-button">
                <IconButton onClick={basketHandler}>
                  <img src={cart} alt="cart" width="21px" />
                </IconButton>
              </div>
              <div className="nav-button">
                <IconButton>{t('AED')}</IconButton>
              </div>
              <div className="nav-button language-item">
                <IconButton
                  onClick={() => {
                    if (lang === 'ar') {
                      setLang('en');
                      languageChange('en');
                    } else {
                      setLang('ar');
                      languageChange('ar');
                    }
                  }}
                >
                  <img src={lang === 'ar' ? usa : uae} alt="language" width="24px" />
                </IconButton>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      {showMenu && (
        <div>
          <SwipeableDrawer
            SlideProps={{ direction: lang === 'ar' ? 'left' : 'right' }}
            className="header-menu-wrapper"
            open={toggle}
            onClose={() => setToggle(false)}
            onOpen={() => setToggle(true)}
          >
            {list()}
          </SwipeableDrawer>
        </div>
      )}
    </div>
  );
}
