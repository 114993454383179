import React,{useCallback} from "react";
import IconButton from "@material-ui/core/IconButton";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { getDownloadableLink } from "../../Helpers/Middleware.Helper";
import { useHistory } from "react-router";
import { RemoveFavoriteTour } from '../../Services';

export const FavoriteCardUiComponent = (props: any): React.ReactElement => {
  const history = useHistory();
  const detailsHandler = () => {
    history.push(`/details?tourId=${props.item.tourId}`);
  };
  const { t } = useTranslation("FavoriteToursUI");
  const RemoveFavorite = useCallback(async (tourId) => {
    const res= await RemoveFavoriteTour(tourId);
    props.setCheckFavorite(false)
    props.setCheckFavorite(undefined)
    console.log(res);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

  return (
    <div>
      <div className="FavoriteCardUiComponentwrapper">
        <div className="Imagewrapper">
          <img
            className="Imagemain"
            src={
              props.item &&
              props.item.tourSummaryTrips.length !== 0 &&
              getDownloadableLink(props.item.tourSummaryTrips[0].tripCoverImage)
            }
            alt="..."
          />
          <div className="place-name">
            <span className="mdi mdi-map-marker" />
            <span className='tripName'>
              {props.item.tourSummaryTrips.length > 0
                ? props.item.tourSummaryTrips[0].tripName
                : 'N/A'}
            </span>
          </div>
          <span 
          onClick={()=>{RemoveFavorite(props.item.tourId)}}
          className={
              props.item.isFavorite
                ? 'mdi mdi-heart mdi-24px favorite hearticon'
                : 'mdi mdi-heart-outline mdi-24px hearticon '
            }></span>
          <img
            className="Imagesecondary"
            src={"https://wallpaperaccess.com/full/2295733.jpg"}
            alt="..."
          />
        </div>
        <div className="wrapper-details">
          <div className="trip-contenar">
            <span className="name-of-trip">{props.item.tourName}</span>
            <span className="ShoppIcon" title={t("Addtocart")}>
              <IconButton
                color="primary"
                size="small"
                aria-label="add to shopping cart"
              >
                <AddShoppingCartIcon />
              </IconButton>
            </span>
          </div>
          <div className="price-contenar">
            <span className="from-price">
              {t("from")}:{props.item.totalFromPrice}
              {t("AED")}
            </span>{" "}
            <Button className="btt-See-details" onClick={detailsHandler}>
              {t("Seedetails")}
            </Button>
          </div>
          <div className="details">
            <div className="details-Container">
              <span className="mdi mdi-map-marker-outline" />
              <span>{props.item.tourSummaryTrips.length}</span>
            </div>
            <div className="details-Container">
              <span className="mdi mdi-account-outline" />
              <span>{props.passenger}</span>
            </div>
            <div className="details-Container">
              <span className="mdi mdi-car-sports" />
              <span>{props.length}</span>
            </div>
            <div className="details-Container">
              <span className="mdi mdi-clock-time-four-outline" />
              <span>{props.item.tourDurationFormat}</span>
            </div>
            <div className="details-Container">
              <span className="mdi mdi-star-outline" />
              <span>{props.item.totalRate.toFixed(1)}</span>
            </div>
          </div>
          {/* <div className="About">{t("Aboutthistour")}</div> */}
          {/* <div className="Content">{t("Aboutdes")}</div> */}
        </div>
      </div>
    </div>
  );
};
