import { ButtonBase } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { GetAllWebsiteSections } from '../../../../../../Services/WebsiteSections/WebsiteSections.Service';
import { ToursRecommendedView, BestToursView } from './Sections';
import { SectionsSkeleton } from './SectionsUtilties/SectionsSkeleton';
export const SectionsView = (props: any) => {
  const [Section, setSection] = React.useState<any[]>([]);
  const [loading, setloading] = React.useState(true);
  const { t } = useTranslation('ToursRecommended');
  const GetAllWebsiteSectionsAPI = React.useCallback(async () => {
    setloading(true);
    const res = await GetAllWebsiteSections(1, 10);
    if(res){
      setSection([...(res as any).result]);
      setloading(false);
    }
    else {setSection([]);}
  }, []);
  React.useEffect(() => {
    GetAllWebsiteSectionsAPI();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <SectionsSkeleton />
      ) : (
        <>
          <div className="d-flex-v-center-h-end px-3">
            <ButtonBase className="btns theme-transparent">
              <span>{t('Discover')}</span>
              <span className="mdi mdi-chevron-right px-1" />
            </ButtonBase>
          </div>
          {Section.map((item, index: number) => {
            if (item.webDesign === 1) {
              return <ToursRecommendedView res={item} key={index} />;
            } else {
              return <BestToursView res={item} key={index} />;
            }
          })}
        </>
      )}
    </>
  );
};
