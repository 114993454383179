import React from 'react';
import label from '../../../../../../Assets/Images/Icons/label.png';
import shiled from '../../../../../../Assets/Images/Icons/shiled.png';
import help from '../../../../../../Assets/Images/Icons/help.png';
import { useTranslation } from 'react-i18next';

export const ServicesView = (props: any) => {
  const { t } = useTranslation('Services');

  return (
    <div className='services-wrapper w-100'>
      <div className='item-wrapper'>
        <div className='item-image'>
          <img alt={t('cancelation-Image')} src={shiled} className='cancelation-size' />
        </div>
        <div className='item-title'>{t('free-cancelation')}</div>
        <div className='item-description'>{t('cancelation-info')}</div>
      </div>
      <div className='item-wrapper'>
        <div className='item-image'>
          <img alt={t('Best-price-image')} src={label} className='price-size' />
        </div>
        <div className='item-title'>{t('best-price')}</div>
        <div className='item-description'>{t('best-price-info')}</div>
      </div>
      <div className='item-wrapper'>
        <div className='item-image'>
          <img alt={t('service-Image')} src={help} className='service-size' />
        </div>
        <div className='item-title'>{t('customer-service')}</div>
        <div className='item-description'>{t('customer-service-info')}</div>
      </div>
    </div>
  );
};
