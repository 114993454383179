import { ButtonBase } from '@material-ui/core';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
const translationPath = 'customTourView.';
const parentTranslationPath = 'Landing';
let isDelay = false;
export const CustomTourView = (): React.ReactElement => {
  const { t } = useTranslation(parentTranslationPath);
  const wheelDelayTimer = useRef<number>(0);
  const mousePosition = useRef<{
    top: number;
    left: number;
    x: number;
    y: number;
  }>({ top: 0, left: 0, x: 0, y: 0 });
  const customTourRef = useRef<HTMLDivElement | null>(null);
  const viewRef = useRef<HTMLDivElement | null>(null);
  const onDragToScrollStart = (event: React.MouseEvent<HTMLDivElement>): void => {
    mousePosition.current = {
      // The current scroll
      left: event.currentTarget.scrollLeft,
      top: event.currentTarget.scrollTop,
      // Get the current mouse position
      x: event.clientX,
      y: event.clientY,
    };
  };
  const dragToScroll = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    const isDragToLeft = mousePosition.current.x >= event.clientX;
    const element: HTMLDivElement = event.currentTarget;
    const isRtl =
      localStorage.getItem('localization') &&
      JSON.parse(localStorage.getItem('localization') as string).isRtl;
    const maxScrollLeft = Math.round(element.scrollWidth - element.clientWidth);
    if (
      (!isDragToLeft &&
        ((!isRtl && element.scrollLeft <= 0) ||
          (isRtl && Math.abs(element.scrollLeft) >= maxScrollLeft))) ||
      (isDragToLeft &&
        ((!isRtl && element.scrollLeft >= maxScrollLeft) || (isRtl && element.scrollLeft >= 0)))
    )
      return;
    let toElement: HTMLDivElement | null = null;
    element.firstElementChild?.childNodes.forEach((node) => {
      if (
        isDragToLeft &&
        ((!isRtl &&
          (node as HTMLDivElement).offsetLeft > event.clientX + element.scrollLeft &&
          !toElement) ||
          (isRtl &&
            Math.abs(element.scrollLeft) > event.clientX - (node as HTMLDivElement).offsetLeft))
      ) {
        toElement = node as HTMLDivElement;
      } else if (
        !isDragToLeft &&
        (node as HTMLDivElement).offsetLeft < element.scrollLeft &&
        ((isRtl && !toElement) || !isRtl)
      )
        toElement = node as HTMLDivElement;
    });
    if (toElement) {
      element.scrollTo({ left: (toElement as HTMLDivElement).offsetLeft });
      (viewRef.current as HTMLDivElement).style.backgroundPosition =
        (Math.abs((toElement as HTMLDivElement).offsetLeft) /
          (element.firstElementChild?.clientWidth || 0)) *
          100 +
        '%';
    }
  };
  const wheelHandler = useCallback((event: WheelEvent): void => {
    if (!event.deltaY || !event.currentTarget) return;
    const isRtl =
      localStorage.getItem('localization') &&
      JSON.parse(localStorage.getItem('localization') as string).isRtl;
    const element: HTMLDivElement = event.currentTarget as HTMLDivElement;
    let maxScrollLeft = Math.round(element.scrollWidth - element.clientWidth);
    // Set scrollDirection (-1 = up // 1 = down)
    let scrollDirection = event.deltaY > 0 ? 1 : -1;

    if (
      (scrollDirection === -1 &&
        ((!isRtl && element.scrollLeft <= 0) || (isRtl && element.scrollLeft >= 0))) ||
      (scrollDirection === 1 &&
        ((!isRtl && element.scrollLeft >= maxScrollLeft) ||
          (isRtl && Math.abs(element.scrollLeft) >= maxScrollLeft)))
    )
      return;
    event.preventDefault();
    event.stopPropagation();
    if (isDelay) return;

    isDelay = true;
    setTimeout(function () {
      isDelay = false;
      wheelDelayTimer.current = 0;
    }, wheelDelayTimer.current);
    let toElement: HTMLDivElement | null = null;

    element.firstElementChild?.childNodes.forEach((node) => {
      if (
        scrollDirection === 1 &&
        ((!isRtl &&
          (node as HTMLDivElement).offsetLeft > event.clientX + element.scrollLeft &&
          !toElement) ||
          (isRtl &&
            Math.abs(element.scrollLeft) > event.clientX - (node as HTMLDivElement).offsetLeft))
      ) {
        toElement = node as HTMLDivElement;
      } else if (
        scrollDirection === -1 &&
        (node as HTMLDivElement).offsetLeft < element.scrollLeft &&
        ((isRtl && !toElement) || !isRtl)
      )
        toElement = node as HTMLDivElement;
    });
    if (toElement) {
      element.scrollTo({ left: (toElement as HTMLDivElement).offsetLeft });
      (viewRef.current as HTMLDivElement).style.backgroundPosition =
        (Math.abs((toElement as HTMLDivElement).offsetLeft) /
          (element.firstElementChild?.clientWidth || 0)) *
          100 +
        '%';
      return;
    }
  }, []);
  useEffect(() => {
    customTourRef.current?.addEventListener(
      'wheel',
      (event: WheelEvent) => {
        if (wheelDelayTimer.current === 0)
          wheelDelayTimer.current =
            (Math.abs(event.deltaY) > 500 && Math.abs(event.deltaY)) || 1000;
        wheelHandler(event);
      },
      {
        passive: false,
      }
    );
  }, [wheelHandler]);
  useEffect(() => {
    const current = customTourRef.current;
    return () => {
      (current as HTMLDivElement).removeEventListener('wheel', () => {});
    };
  }, []);
  return (
    <div className="custom-tour-view-wrapper childs-wrapper" ref={viewRef}>
      <div className="custom-tour-title-wrapper">
        <div className="first-section">{t(`${translationPath}title-1-description`)}</div>
        <div className="second-section">{t(`${translationPath}title-2-description`)}</div>
      </div>
      <div
        className="tracker-wrapper"
        ref={customTourRef}
        onMouseUp={dragToScroll}
        onMouseDown={onDragToScrollStart}
      >
        <div className="tracker-items-wrapper">
          <div className="tracker-item-wrapper">
            <div className="tracker-item-circle-wrapper">
              <div className="tracker-item-circle">1</div>
            </div>
            <div className="tracker-item-text">
              {t(`${translationPath}tracker-item-1-description`)}
            </div>
          </div>
          <div className="tracker-item-wrapper">
            <div className="tracker-item-circle-wrapper">
              <div className="tracker-item-circle">2</div>
            </div>
            <div className="tracker-item-text">
              {t(`${translationPath}tracker-item-2-description`)}
            </div>
          </div>
          <div className="tracker-item-wrapper">
            <div className="tracker-item-circle-wrapper">
              <div className="tracker-item-circle">3</div>
            </div>
            <div className="tracker-item-text">
              {t(`${translationPath}tracker-item-3-description`)}
            </div>
          </div>
        </div>
      </div>
      <div className="button-container">
        <ButtonBase className="btns theme-outline theme-dark">
          <span>{t(`${translationPath}start-creating-tour`)}</span>
        </ButtonBase>
      </div>
    </div>
  );
};
