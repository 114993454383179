import { ButtonBase, PopoverOrigin } from '@material-ui/core';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PopoverUiComponent } from '../Popover/Popover.UiComponent';
interface DropdownUiComponentProps {
  popoverComponent: React.ReactElement;
  emptyLabel?: string;
  onIsOpenChanged?: (isOpen: boolean) => void;
  contentComponent?: (isBlurOrChanged: boolean) => React.ReactNode;
  titleIcon?: string;
  titleText?: string;
  helperText?: string;
  popoverIdRef?: string;
  idRef?: string;
  translationPath?: string;
  parentTranslationPath?: string;
  labelValue?: string;
  labelClasses?: string;
  wrapperClasses?: string;
  popoverClasses?: string;
  themeClass?: 'theme-dark';
  isDisabled?: boolean;
  hasError?: boolean;
  withBackdrop?: boolean;
  isSubmitted?: boolean;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}
export const DropdownUiComponent = ({
  popoverComponent,
  emptyLabel,
  contentComponent,
  popoverIdRef = 'dropdownPopoverRef',
  idRef = 'dropdownPickerRef',
  translationPath = '',
  parentTranslationPath = '',
  labelValue,
  labelClasses = '',
  wrapperClasses = '',
  popoverClasses,
  themeClass,
  isDisabled,
  hasError,
  isSubmitted,
  titleIcon,
  titleText,
  helperText,
  onIsOpenChanged,
  anchorOrigin,
  transformOrigin,
  withBackdrop,
}: DropdownUiComponentProps): React.ReactElement => {
  const { t } = useTranslation(parentTranslationPath);
  const [attachedWith, setAttachedWith] = useState(null);
  const [isBlurOrChanged, setIsBlurOrChanged] = useState(false);
  const displayRef = useRef(null);
  const handleClose = useCallback(() => {
    setAttachedWith(null);
    if (onIsOpenChanged) onIsOpenChanged(false);
  }, [onIsOpenChanged]);
  const openHandler = useCallback(() => {
    if (!isBlurOrChanged) setIsBlurOrChanged(true);
    if (onIsOpenChanged) onIsOpenChanged(true);
    setAttachedWith(displayRef.current);
  }, [isBlurOrChanged, onIsOpenChanged]);
  return (
    <div
      className={`dropdown-component ${wrapperClasses}${
        (hasError && (isBlurOrChanged || isSubmitted) && ' has-error') || ''
      }${(attachedWith && ' is-open') || ''} ${themeClass || ''}`}
    >
      {labelValue && (
        <label
          htmlFor={idRef}
          className={`label-wrapper ${labelClasses}${isDisabled ? ' disabled' : ''}`}
        >
          {t(`${translationPath}${labelValue}`)}
        </label>
      )}
      <ButtonBase
        id={idRef}
        className="display-wrapper"
        disabled={isDisabled}
        ref={displayRef}
        onClick={openHandler}
      >
        {(titleIcon || titleText) && (
          <div className="description-text-wrapper">
            {titleIcon && <span className={`description-icon ${titleIcon}`} />}
            {titleText && (
              <span className="description-text">{t(`${translationPath}${titleText}`)}:</span>
            )}
          </div>
        )}
        <div className="dropdown-content-wrapper">
          {(contentComponent && contentComponent(isBlurOrChanged)) || (
            <span className="description-text">{t(`${translationPath}${emptyLabel}`)}</span>
          )}
        </div>
        <span className={`arrow-icon mdi mdi-chevron-${(attachedWith && 'up') || 'down'}`} />
      </ButtonBase>
      {helperText && (isBlurOrChanged || isSubmitted) && (
        <span className="error-wrapper">{helperText}</span>
      )}
      <PopoverUiComponent
        idRef={popoverIdRef}
        handleClose={handleClose}
        attachedWith={attachedWith}
        component={popoverComponent}
        popoverClasses={popoverClasses}
        themeClass={themeClass}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        withBackdrop={withBackdrop}
      />
    </div>
  );
};
