import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../../Helpers/HttpMethod.Helper';

export const GetAllTripReviewsByTourId = async (tourId: any, pageIndex: any, pageSize: any) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/TripReview/GetAllTripReviewsByTourId/${tourId}?pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
    return result;
  } catch (e) {
    return;
  }
};

export const GetMyPastTour = async (pageIndex: any, pageSize: any) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/Tours/GetMyPastTour?pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
    return result;
  } catch (e) {
    return;
  }
};

export const GetMyOngoingTour = async (pageIndex: any, pageSize: any) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/Tours/GetMyOngoingTour?pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
    return result;
  } catch (e) {
    return;
  }
};

export const GetMyPastForMe = async (pageIndex: any, pageSize: any) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/Tours/GetMyPastTour?pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
    return result;
  } catch (e) {
    return;
  }
};
