import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export let PrivateRoute: ({component: Component, login, addRoute, ...rest}: { component: any; login?: any; addRoute: any; [p: string]: any }) => any;
PrivateRoute = ({
                    component: Component,
                    login = '/account/login',
                    addRoute,
                    ...rest
                }) => (
    <Route
        {...rest}
        render={(props) =>
            (localStorage.getItem('session') ? (
                <Component {...props} addRoute={addRoute}/>
            ) : (
                <Redirect to={login}/>
            ))}
    />
);
