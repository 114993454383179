import React from 'react';
import { Popover, PopoverOrigin } from '@material-ui/core';
interface PopoverUiComponentProps {
  component: React.ReactElement;
  idRef: string;
  attachedWith?: Element | ((element: Element) => Element) | null;
  handleClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  popoverClasses?: string;
  themeClass?: 'theme-dark';
  style?: object;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  withBackdrop?: boolean;
}
export const PopoverUiComponent = ({
  component,
  idRef,
  attachedWith,
  handleClose,
  popoverClasses = '',
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'center',
  },
  style,
  themeClass,
  withBackdrop,
}: PopoverUiComponentProps) => {
  const open = Boolean(attachedWith);
  return (
    <Popover
      id={idRef}
      open={open}
      anchorEl={attachedWith}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      style={style}
      transformOrigin={transformOrigin}
      className={`popover-wrapper ${popoverClasses} ${themeClass || ''}${
        (withBackdrop && ' with-backdrop') || ''
      }`}
    >
      {component}
    </Popover>
  );
};
