import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { screenSize, deviceOs } from '../../../../../../Enums';
import { useElementSize, useDevice } from '../../../../../../Hooks';
import mobilePreview from '../../../../../../Assets/Videos/preview.Videos.mp4';

export const DownloadMobileAppView = (props: any) => {
  const [showList, setShoewList] = useState(false);
  const downloadRef = useRef<HTMLDivElement>(null);
  const downloadSize = useElementSize(downloadRef);
  const { t } = useTranslation('Download');

  const GetDeviceOs = () => {
    const result = useDevice();
    return result;
  };

  useEffect(() => {
    downloadSize.width <= screenSize.phonesMax ? setShoewList(false) : setShoewList(true);
  }, [downloadSize]);

  return (
    <div className="download-wrapper">
      <div className="download-content" ref={downloadRef}>
        <div className="download-mobile">
          <video className="download-video" autoPlay muted>
            <source src={mobilePreview} type="video/mp4" />
          </video>
        </div>
        <div className="download-text">
          <div className="download-app">{t('download-the-application')}</div>
          {showList && <div className="download-description">{t('download-description')}</div>}
          {!showList ? (
            <div className="download-buttons">
              {GetDeviceOs() === deviceOs.windowsPhone && (
                <div className="windows">
                  <Button disabled>
                    <span className="mdi mdi-microsoft-windows" />
                    <div className="download-windows">
                      <div>{t('download-from')}</div>
                      <div className="download-windows">{t('windows-store')}</div>
                    </div>
                  </Button>
                </div>
              )}
              {GetDeviceOs() === deviceOs.ios && (
                <div>
                  <Button>
                    <span className="mdi mdi-apple" />
                    <div className="download-store">
                      <div>{t('download-on-the')}</div>
                      <div className="download-apple">{t('app-store')}</div>
                    </div>
                  </Button>
                </div>
              )}
              {GetDeviceOs() === deviceOs.android && (
                <div>
                  <Button>
                    <span className="mdi mdi-google-play" />
                    <div className="download-store">
                      <div>{t('get-it-on')}</div>
                      <div className="download-google">{t('google-play')}</div>
                    </div>
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <div className="download-buttons">
              <div>
                <Button>
                  <span className="mdi mdi-apple" />
                  <div className="download-store">
                    <div>{t('download-on-the')}</div>
                    <div className="download-apple">{t('app-store')}</div>
                  </div>
                </Button>
              </div>
              <div>
                <Button>
                  <span className="mdi mdi-google-play" />
                  <div className="download-store">
                    <div>{t('get-it-on')}</div>
                    <div className="download-google">{t('google-play')}</div>
                  </div>
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
