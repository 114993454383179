import * as React from 'react';
import { ButtonBase } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { useTranslation } from 'react-i18next';
import { ToursRecommendedSkeleton } from './RectangleBestToursUtilties/Tours.Recommended.Skeleton';
import { getDownloadableLink } from '../../Helpers/Middleware.Helper';
export const RectangleBestTours = (props: any) => {
  const { t } = useTranslation('BestTours');

  return (
    <div className="flex-container ">
      {!props.loading ? (
        props.Data &&
        props.Data.map((Data: any, index: number) => {
          return (
            <div className={index === 1 ? 'card-border-cinter' : 'card-border'} key={index}>
              <div className="firstcard">
                <span
                  className={
                    Data.isFavorite !== 'true'
                      ? 'mdi mdi-heart-outline favoritehart'
                      : 'mdi mdi-heart isfavoritehart'
                  }
                />
                <img
                  alt="img"
                  src={getDownloadableLink(Data.galleryList[0])}
                  className="img-center"
                />
                <div className={index === 1 ? 'text-big-bold-center' : 'text-big-bold'}>
                  {' '}
                  {Data.tourName || t('N/A')}
                </div>
                <div className="text-small">
                  {Data.tourSummaryTrips.length || ' ' || t('N/A')} {t('Distinations')}
                </div>
                <div className='text-small-rating'>
                  <Rating name='read-only' size='small' value={(Data&&Data.tourSummaryTrips&&Data.tourSummaryTrips[0] &&Data.tourSummaryTrips[0].totalTripReview ) || 0} readOnly />
                  <div className='textrating'>
                    {(Data&&Data.tourSummaryTrips&&Data.tourSummaryTrips[0] &&Data.tourSummaryTrips[0].totalTripRating) || 0}
                    {t('View')}
                  </div>
                </div>
                <div className="text-small">{t('from')}</div>
                <div className="text-big-bold">
                  <div>
                    {Data.totalFromPriceAfterDiscount || t('N/A')}
                    &nbsp; {t('AED')}
                  </div>
                  <ButtonBase className="btns">{t('Addtocart')}</ButtonBase>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <ToursRecommendedSkeleton />
      )}
    </div>
  );
};
