import loadable from '@loadable/component';
const LandingView = loadable(
  () => import('../../../Views/MainLayoutViews/HomeLayoutsViews/LandingView/Landing.View')
);

const FavoriteToursView = loadable(
  () => import('../../../Views/FavoriteLayoutViews/FavoriteViews/FavoriteLayout.Views')
);

const FavoriteDatailsView = loadable(
  () => import('../../../Views/TourDetailsLayoutViews/DetailsViews/TourDetailsLayout.View')
);
const AllToursView = loadable(
  () => import('../../../Views/AllToursLayoutViews/AllToursViews/AllToursLayout.View')
);

const UserLayoutViews = loadable(
  () => import('../../../Views/UserLayoutViews/UserViews/UserLayout.Views')
);

const BasketToursView = loadable(
  () => import('../../../Views/BasketLayoutViews/BasketViews/BasketLayout.Views')
);
const CreateTourViews = loadable(() => import('../../../Views/CreateTourViews/CreateTour.Views'));
const MyTours = loadable(() => import('../../../Views/MyToursViews/MyToursLayout.Views'));
export const MainRoute: any = [
  {
    path: '/landing',
    name: 'Landing',
    component: LandingView,
    layout: '',
    default: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '/favorite',
    name: 'Favorite',
    component: FavoriteToursView,
    layout: '',
    default: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '/AllTours',
    name: 'All Tours',
    component: AllToursView,
    layout: '',
    default: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '/details',
    name: 'Details',
    component: FavoriteDatailsView,
    layout: '',
    default: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '/basket',
    name: 'Basket',
    component: BasketToursView,
    layout: '',
    default: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '/user',
    name: ' User',
    component: UserLayoutViews,
    layout: '',
    default: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '/createTour',
    name: 'createTour',
    component: CreateTourViews,
    layout: '',
    default: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '/MyTours',
    name: ' MyTours',
    component: MyTours,
    layout: '',
    default: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
];
