
import React from 'react';
import image from '../../../../Assets/Images/Defaults/DefaultEmpty.png';
import { useTranslation } from "react-i18next";
export const NothingOnBasketView = (props: any) => {
  const { t } = useTranslation("FavoriteTours");
  return (
    <div className='NothingOnBasketView'>
      <div className='Contral'>
        <div className='text-title'>{t('Nothing-on-basket-yet')}</div>
      </div>
      <div className='flex-containerNotour'>
        <div className='Notouryetwrapper'>
          <img className='ImageNotouryet' src={image} alt='...' />
        </div>
      </div>
    </div>
  );
};
