import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { localizationInit } from './TranslateMethod.Helper';
import config from '../Assets/Configration/Configration.json';
import { ValidationResult } from 'joi';
export let GlobalTranslate: any = null;
export let GlobalHistory: any = null;
let renderVar = false;
let setRenderVar: any = null;
export let SetGlobalRerender = (setRender: Function, render: boolean) => {
  renderVar = render;
  setRenderVar = setRender;
};
export let GlobalRerender = () => {
  setRenderVar(!renderVar);
};

function MiddlewareHelper() {
  GlobalTranslate = useTranslation();
  GlobalHistory = useHistory();
  return null;
}
localizationInit();
export function getDownloadableLink(fileName: String) {
  return `${config.serverAddress}/FileManager/File/DownloadPublicFile/${fileName}`;
}
export function getDownloadablePrivateLink(fileId: string) {
  const session = JSON.parse(localStorage.getItem('session') as string);
  const userId = session ? session.userId : '';
  const fileToken = session ? session.fileToken : '';
  return `${config.serverAddress}/FileManager/File/DownloadFile/${userId}/${config.applicationId}/${fileToken}/${fileId}`;
}

export const getErrorByName = (schemaObject: ValidationResult, fieldName: string) => {
  if (!schemaObject.error || !schemaObject.error.details)
    return {
      message: undefined,
      error: undefined,
    };
  const item = schemaObject.error.details.find((item) => {
    return (
      (!fieldName.includes('.') && item.path.includes(fieldName)) ||
      (fieldName.includes('.') &&
        item.path.length >= fieldName.split('.').length &&
        item.path.slice(0, fieldName.split('.').length).join('.') === fieldName)
    );
  });
  if (!item)
    return {
      message: undefined,
      error: undefined,
    };
  return {
    message: item.message,
    error: true,
  };
};
export { MiddlewareHelper as Middleware };
