
export const toPascalCase = (oldObject:any) => {
    const newObject:any = {};
    let newKey:any;
    let value:any;
    if (oldObject instanceof Array) {
        return oldObject.map((item) => {
            if (typeof item === 'object') value = toPascalCase(item);
            return value;
        });
    }
    Object.keys(oldObject).map((origKey) => {
        if (oldObject.hasOwnProperty.call(origKey)) {
            newKey = (origKey.charAt(0).toUpperCase() + origKey.slice(1) || origKey).toString();
            value = oldObject[origKey];
            if (value instanceof Array || (value !== null && value.constructor === Object))
                value = toPascalCase(value);
            newObject[newKey] = value;
        }
        return undefined;
    });

    return newObject;
};
export const toCamelCase = (oldObject: any) => {
    const newObject: any = {};
    let newKey;
    let value:any;
    if (oldObject instanceof Array) {
        return oldObject.map((item) => {
            if (typeof item === 'object') value = toPascalCase(item);
            return value;
        });
    }
    Object.keys(oldObject).map((origKey) => {
        if (oldObject.hasOwnProperty.call(origKey)) {
            newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
            value = oldObject[origKey];
            if (value instanceof Array || (value !== null && value.constructor === Object))
                value = toPascalCase(value);
            newObject[newKey] = value;
        }
        return undefined;
    });

    return newObject;
};
