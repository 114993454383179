import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../../Helpers/HttpMethod.Helper';

export const GetAllWebsiteSections = async (pageIndex: Number, pageSize: Number) => {
  try {
     const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/ApplicationSection/GetAllWebsiteSections/${config.organizationsId}/${config.applicationId}?pageIndex=${pageIndex}&pageSize=${pageSize}`
   , { headers: { isPublic: true } } );
    return result;
  } catch (e) {
    return;
  }
};

export const GetAllToursBySectionIdPublic = async (
  sectionId: any,
  pageIndex: Number,
  pageSize: Number,
  payload: any
) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/ApplicationSection/GetAllToursBySectionIdPublic/${sectionId}/${config.organizationsId}/${config.applicationId}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      payload,
      { headers: { isPublic: true } }
    );
    return result;
  } catch (e) {
    return;
  }
};
