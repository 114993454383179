import contant from '../Assets/Images/images/profile.png'
import BuildingsImg from '../Assets/Images/Defaults/buildings.png'
export const ContactTypeEnum = {
  contant: {
    value: 'user',
    defaultImg: contant,
  },
  buildings: {
    key: 'buildings',
    defaultImg: BuildingsImg,
}
}
