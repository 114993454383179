import { ButtonBase } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetDefaultCarTypeForPublicDto,
  GetRecommendedTourCitiesPublicFilterBy,
  CarSearchDto,
} from '../../../../../../../../Services';
interface PassengersViewProps {
  filterBy: GetRecommendedTourCitiesPublicFilterBy;
  defaultCar?: GetDefaultCarTypeForPublicDto;
  onFilterByChanged: (FilterByNewValue: GetRecommendedTourCitiesPublicFilterBy) => void;
  translationPath: string;
  parentTranslationPath: string;
}
export const PassengersView = ({
  defaultCar,
  filterBy,
  onFilterByChanged,
  translationPath,
  parentTranslationPath,
}: PassengersViewProps): React.ReactElement => {
  const { t } = useTranslation(parentTranslationPath);
  const getTotalHandler = (objectDto: keyof CarSearchDto): number => {
    if (objectDto === 'carTypeId') return 0;
    return filterBy.carSearch.reduce((total, item) => {
      total += (item && item[objectDto] && item[objectDto].length) || 0;
      return total;
    }, 0);
  };
  const filterByChangedHandler = (
    objectDto: keyof CarSearchDto,
    value: 'splice' | 'push'
  ) => () => {
    if (objectDto === 'carTypeId' || !defaultCar || !defaultCar.carTypesId) return;

    if (value === 'push') {
      const findNextEmptyPlace: number = filterBy.carSearch.findIndex(
        (item) =>
          item.adults.length + item.childrens.length + item.infants.length < defaultCar.noOfSets
      );
      if (findNextEmptyPlace !== -1)
        (filterBy.carSearch[findNextEmptyPlace][objectDto] as string[]).push(
          `${objectDto} ${getTotalHandler(objectDto) + 1}`
        );
      else
        filterBy.carSearch.push({
          carTypeId: defaultCar.carTypesId,
          adults: [],
          childrens: [],
          infants: [],
          [objectDto]: [`${objectDto} ${getTotalHandler(objectDto) + 1}`],
        });
    } else {
      let lastAvailableIndex: number = -1;
      filterBy.carSearch.forEach((item, index) => {
        if (item[objectDto].length > 0) lastAvailableIndex = index;
      });
      filterBy.carSearch[lastAvailableIndex][objectDto].pop();
    }
    onFilterByChanged(filterBy);
  };
  return (
    <div className="passengers-wrapper childs-wrapper">
      <div className="header-wrapper">
        <span>{t(`${translationPath}num-of-passengers`)}</span>
      </div>
      <div className="body-wrapper">
        <div className="item-wrapper">
          <div className="item-body">
            <ButtonBase
              className="btns-icons theme-outline"
              disabled={
                !filterBy.carSearch ||
                filterBy.carSearch.length === 0 ||
                getTotalHandler('adults') === 0
              }
              onClick={filterByChangedHandler('adults', 'splice')}
            >
              <span className="mdi mdi-minus" />
            </ButtonBase>
            <div className="details-wrapper">
              <div>
                <span>{getTotalHandler('adults')}</span>
                <span className="px-1">{t(`${translationPath}adults`)}</span>
              </div>
              <span className="description-text">{t(`${translationPath}adults-description`)}</span>
            </div>
            <ButtonBase
              className="btns-icons theme-outline"
              onClick={filterByChangedHandler('adults', 'push')}
            >
              <span className="mdi mdi-plus" />
            </ButtonBase>
          </div>
          {/* <div className="item-footer">
            {filterBy.carSearch.map((item, index) =>
              item.adults.map((subItem, subIndex) => (
                <InputsUiComponent
                  idRef={`adultsNameRef${index}${subIndex}}`}
                  value={subItem}
                  inputPlaceholder="adult-name"
                  wrapperClasses="theme-underline"
                  themeClass="theme-dark"
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  onInputChanged={(event: React.ChangeEvent<HTMLInputElement>) => {
                    (filterBy.carSearch[index].adults[subIndex] as string) = event.target.value;
                    if (onFilterByChanged) onFilterByChanged(filterBy);
                  }}
                />
              ))
            )}
          </div> */}
        </div>
        <div className="item-wrapper">
          <div className="item-body">
            <ButtonBase
              className="btns-icons theme-outline"
              disabled={
                !filterBy.carSearch ||
                filterBy.carSearch.length === 0 ||
                getTotalHandler('childrens') === 0
              }
              onClick={filterByChangedHandler('childrens', 'splice')}
            >
              <span className="mdi mdi-minus" />
            </ButtonBase>
            <div className="details-wrapper">
              <div>
                <span>{getTotalHandler('childrens')}</span>
                <span className="px-1">{t(`${translationPath}children`)}</span>
              </div>
              <span className="description-text">
                {t(`${translationPath}children-description`)}
              </span>
            </div>
            <ButtonBase
              className="btns-icons theme-outline"
              onClick={filterByChangedHandler('childrens', 'push')}
            >
              <span className="mdi mdi-plus" />
            </ButtonBase>
          </div>
          {/* <div className="item-footer">
            {filterBy.carSearch.map((item, index) =>
              item.childrens.map((subItem, subIndex) => (
                <InputsUiComponent
                  idRef={`childrensNameRef${index}${subIndex}`}
                  value={subItem}
                  inputPlaceholder="children-name"
                  wrapperClasses="theme-underline"
                  themeClass="theme-dark"
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  onInputChanged={(event: React.ChangeEvent<HTMLInputElement>) => {
                    filterBy.carSearch[index].childrens[subIndex] = event.target.value;
                    if (onFilterByChanged) onFilterByChanged(filterBy);
                  }}
                />
              ))
            )}
          </div> */}
        </div>
        <div className="item-wrapper">
          <div className="item-body">
            <ButtonBase
              className="btns-icons theme-outline"
              disabled={
                !filterBy.carSearch ||
                filterBy.carSearch.length === 0 ||
                getTotalHandler('infants') === 0
              }
              onClick={filterByChangedHandler('infants', 'splice')}
            >
              <span className="mdi mdi-minus" />
            </ButtonBase>
            <div className="details-wrapper">
              <div>
                <span>{getTotalHandler('infants')}</span>
                <span className="px-1">{t(`${translationPath}infants`)}</span>
              </div>
              <span className="description-text">{t(`${translationPath}infants-description`)}</span>
              <span className="description-text">
                {t(`${translationPath}infants-note-description`)}
              </span>
            </div>
            <ButtonBase
              className="btns-icons theme-outline"
              onClick={filterByChangedHandler('infants', 'push')}
            >
              <span className="mdi mdi-plus" />
            </ButtonBase>
          </div>
          {/* <div className="item-footer">
            {filterBy.carSearch.map((item, index) =>
              item.infants.map((subItem, subIndex) => (
                <InputsUiComponent
                  idRef={`infantsNameRef${index}${subIndex}`}
                  value={subItem}
                  inputPlaceholder="infant-name"
                  wrapperClasses="theme-underline"
                  themeClass="theme-dark"
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  onInputChanged={(event: React.ChangeEvent<HTMLInputElement>) => {
                    (filterBy.carSearch[index].infants[subIndex] as string) = event.target.value;
                    if (onFilterByChanged) onFilterByChanged(filterBy);
                  }}
                />
              ))
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
};
