import * as React from 'react';
import {
  Timeline,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
} from '@material-ui/lab';
import Skeleton from '@material-ui/lab/Skeleton';
export const ToursRecommendedSkeleton = (props: any) => {
  var numbers: any = [];
  for (var i = 1; i <= props.CountOfCard; i++) {
    numbers.push(i);
  }
  const numbersCount = [1, 2, 3, 4, 5];

  const listItems = numbersCount.map((number, index: number) => (
    <div className="card-point-container" key={index}>
      <div className="card-point">
        <Timeline>
          {numbers &&
            numbers.map((ItemDetales: any, index: number) => (
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <div className="details">
                    <div className="detaile-name">
                      <Skeleton animation="wave" height="25px" width="170px" />
                    </div>
                    <div>
                      <Skeleton animation="wave" height="25px" width="18px" />
                    </div>
                  </div>
                  <div className="Image-container">
                    {' '}
                    <Skeleton animation="wave" height="101px" width="198px" />
                  </div>
                </TimelineContent>
              </TimelineItem>
            ))}
        </Timeline>
      </div>
      <div className="contact-field">
        <Skeleton animation="wave" height="25px" width="145px" />

        <div className={'mdi mdi-heart-outline favoritebuuton'} />
      </div>
    </div>
  ));

  return (
    <div className="seaction-continer">
      <div className="seaction-continer">
        <div className="flex-container-ToursRecommended">{listItems}</div>
      </div>
    </div>
  );
};
