/* eslint-disable no-unused-vars */
export enum screenSize {
  laptopsXxlMax = 2560,
  laptopsXlMax = 1920,
  laptopsLMax = 1440,
  laptopsMax = 1200,
  tabletsMax = 992,
  phonesMax = 767,
  phonesSMax = 576,
  phonesXsMax = 320,
}
