import React, { useState } from 'react';
import { NothingOnBasketView } from './NothingOnBasket.View';
import { useTranslation } from 'react-i18next';
import { useEventListener } from '../../../../Hooks';
import { GetMyBasket } from './../../../../Services';
import { SpinnerUiComponent } from './../../../../UiComponentes/Spinner/Spinner.UiComponent';
import { BasketCardUiComponents } from './../../../../UiComponentes/BasketCard/BasketCard.UiComponents';
import { ButtonBase } from '@material-ui/core';
import { Button } from '@material-ui/core';
export const OnBasketView = (props: any) => {
  const { t } = useTranslation('FavoriteTours');
  const translationPath = '';
  const [loading, setloading] = useState(true);
  const [result, setResult] = useState<any[]>([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isActive, setisActive] = useState(true);
  const GetBasketTours = React.useCallback(async () => {
    setloading(true);
    const res = await GetMyBasket();
    res && setResult((res && (res as any)) || []);
    setTotalCount(0);
    setloading(false);
  }, []);

  const handleScroll = (event: React.MouseEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollHeight - scrollTop - 1 < clientHeight)
      totalCount > pageIndex && setPageIndex((prev) => prev + 1);
  };
  React.useEffect(() => {
    GetBasketTours();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);
  useEventListener('scroll', handleScroll);
  console.log(result, 'onbasket');
  return (
    <div className='BasketTourswrapper' onScroll={handleScroll}>
      <SpinnerUiComponent isActive={loading} isAbsolute />
      <div className='title-conteaner'>
        <div className='main-title'>{t('Basket')}</div>
        {/* <div className="description">{t("description")}</div> */}
      </div>
      <div className='opation-wraper'>
        <div className='opation'>
          <ButtonBase
            className={
              isActive === false
                ? 'opation-bbt theme-transparent'
                : 'opation-bbt theme-transparent isActive'
            }
            onClick={() => setisActive(true)}>
            <span className='opacity-1'>{t(`${translationPath}Basket`)}</span>
          </ButtonBase>
          <ButtonBase
            className={
              isActive === true
                ? 'opation-bbt theme-transparent'
                : 'opation-bbt theme-transparent isActive'
            }
            onClick={() => setisActive(false)}>
            <span className='opacity-1'>{t(`${translationPath}Saved-for-later`)}</span>
          </ButtonBase>
        </div>
      </div>
      <div className={loading ? 'flex-container' : ''}>
        {totalCount !== 0 ? (
          <NothingOnBasketView />
        ) : (
          <div className='basket-tours-cards-wrapper'>
            <BasketCardUiComponents />

            <div className='Total-summary'>
              <div className='summary'>
                {' '}
                <div className='titel'>Total-summary</div>
              </div>
              <div className='tour'>
                <div className='tour-name'>
                  <div className='tour-order'>tour1</div>
                  <div className='order-name'> - Burj khaleefa Dubai</div>
                </div>
                <div className='details'>
                <div className='Show-details' > Show details</div>
                  <div className='price'>175</div>
                </div>
              </div>
              <div className='tour'>
                <div className='tour-name'>
                  <div className='tour-order'>tour1</div>
                  <div className='order-name'> - Burj khaleefa Dubai</div>
                </div>
                <div className='details'>
                <div className='Show-details' > Show details</div>
                  <div className='price'>350</div>
                </div>
              </div>
              <div className='tour'>
                <div className='tour-name'>
                  <div className='tour-order' >tour1</div>
                  <div className='order-name'> - Burj khaleefa Dubai</div>
                </div>
                <div className='details'>
                  <div className='Show-details' > Show details</div>
                  <div className='price'>205</div>
                </div>
              </div>
              <div className='total'>
                <div className='total-title'>
                 <div> total</div> 
                 <div> -Discount</div>
                 <div className='price'>-Total price</div>
                </div>
                <div className='total-price'>
                <div> 2500</div> 
                 <div>400</div>
                 <div className='price'>2100</div>
                </div>
                
              </div>
              <Button className='checkout'>trtrtr</Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
