import React from 'react';
import logo from '../../../../Assets/Images/Defaults/psiLogoDark.png';
import { useTranslation } from 'react-i18next';
import { TextField, Button, Divider } from '@material-ui/core';
import { GetInTouch } from '../../../../Services/NewsLetter/News.Letter';
import { expressions } from '../../../../Enums/expressions.enum';
import Joi from 'joi';

export const FooterView = () => {
  const { t } = useTranslation('Footer');
  const [email, setEmail] = React.useState('');
  const schema = Joi.object({
    email: Joi.string().required().regex(new RegExp(expressions.emailExpression)).messages({
      'string.pattern.base': 'Add valed email',
      'string.empty': 'Add valed email',
    }),
  })
    .options({
      abortEarly: false,
    })
    .validate({ email });

  return (
    <div className='footer-wrapper w-100'>
      <div className='footer-logo'>
        <img src={logo} alt={t('psitours')} className='content-img' />
      </div>
      <div className='footer-content'>
        <div className='left-section'>
          <div className='top-content'>
            <div className='content-title'>{t('psitours')}</div>
            <div className='content-description'>{t('content-description')}</div>
          </div>
          <div className='bottom-content'>
            <div className='contact-us'>
              <div className='get-in-touch'>{t('get-in-touch')}</div>
              <div className='get-description'>{t('get-description')}</div>
              <div className='contact-field'>
                <TextField
                  size='small'
                  onChange={(e) => setEmail(e.target.value)}
                  type='email'
                  error
                  helperText={schema.error && email ? t('Incorrect-Email') : ''}
                  value={email}
                  placeholder={t('enter-email')}
                />
                <Button
                  type='submit'
                  className={!schema.error && email ? 'btns disabled-btn' : ''}
                  disabled={!schema.error && email ? false : true}
                  onClick={async () => {
                    await GetInTouch({
                      email: email,
                    });
                    setEmail('');
                  }}>
                  <span>{t('send')}</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className='right-section'>
          <div className='content-list'>
            <div className='left-list'>
              <div className='content-item'>{t('all-tours')}</div>
              <div className='content-item'>{t('dubai-tours')}</div>
              <div className='content-item'>{t('abu-dhabi-tours')}</div>
              <div className='content-item'>{t('offered-tours')}</div>
              <div className='content-item'>{t('recommended-tours')}</div>
              <div className='content-item'>{t('custom-tour')}</div>
            </div>
            <div className='right-list'>
              <div className='content-item'>{t('profile')}</div>
              <div className='content-item'>{t('upcoming-tours')}</div>
              <div className='content-item'>{t('past-tours')}</div>
              <div className='content-item'>{t('basket-tours')}</div>
              <div className='content-item'>{t('saved-for-later')}</div>
              <div className='content-item'>{t('favorite-tours')}</div>
            </div>
          </div>
          <div className='contact-support'>
            <div className='contact-support-title'>{t('contact-support')}</div>
            <div className='contact-support-content'>
              <div className='contact-support-item suggestion'>
                <div className='support-item'>
                  {t('suggestion')}
                  <span className='mdi mdi-chevron-right' />
                </div>
                <Divider />
              </div>
              <div className='contact-support-item complaint'>
                <div className='support-item'>
                  {t('complaint')}
                  <span className='mdi mdi-chevron-right' />
                </div>
                <Divider />
              </div>
              <div className='contact-support-item tribute'>
                <div className='support-item'>
                  {t('tribute')}
                  <span className='mdi mdi-chevron-right' />
                </div>
                <Divider />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='footer-bottom w-100'>
        <div className='copy-right-items'>
          <div className='footer-copyright'>
            <div className='privacy-terms'>
              <div className='terms-item'>{t('privacy-policy')}</div>
              <div className='terms-item'>{t('terms-conditions')}</div>
            </div>
          </div>
          <div className='footer-social'>
            <div className='download-application'>
              {t('download-application')}
              <span className='mdi mdi-chevron-down' />
            </div>
            <div className='social-items'>
              <div className='social-item facebook' />
              <div className='social-item twitter' />
              <div className='social-item instagram' />
            </div>
          </div>
        </div>
        <div className='copy-right'>{t('copy-right')}</div>
      </div>
    </div>
  );
};
