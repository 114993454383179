import { ButtonBase } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEventListener } from '../../../../../../Hooks';
import {
  AutocompleteUiComponent,
  DateRangePickerUiComponent,
  DropdownUiComponent,
  LoadableImageUiComponant,
  SpinnerUiComponent,
} from '../../../../../../UiComponentes';
import { getDownloadableLink } from '../../../../../../Helpers';
import DefaultImages from '../../../../../../Assets/Json/DefaultImages.json';
import { PassengersView, CarsView } from './Dialogs';
import {
  CarSearchDto,
  Errors,
  FilterDto,
  GetDefaultCarTypeForPublic,
  GetDefaultCarTypeForPublicDto,
  GetRecommendedTourCitiesPublic,
  GetRecommendedTourCitiesPublicDto,
  GetRecommendedTourCitiesPublicFilterBy,
} from '../../../../../../Services';
import { showError } from '../../../../../../Helpers';
import defaultImage from '../../../../../../Assets/Images/Defaults/buildings.svg';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalFilterActions } from '../../../../../../Stores/Actions';
import { LoadableImageEnum } from '../../../../../../Enums';
// import { GlobalFilterActions } from '../../../../../../Stores/Actions';
const translationPath = 'toursDiscoverView.';
const parentTranslationPath = 'Landing';
interface Images {
  tourId: number | null;
  totalTrips: number;
  imageTitle: string;
  imageUrl: string | null;
  tourName: string | null;
  totalRate: number | null;
  isFavorite: boolean;
  width: number;
  height: number;
  x: number;
  y: number;
}

export const ToursDiscoverView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(parentTranslationPath);
  // const [language] = useLocalStorage<{ currentLanguage: string; isRtl: boolean }>('localization', {
  //   currentLanguage: 'en',
  //   isRtl: false,
  // });
  const dispatch = useDispatch();
  const globalFilterReducers = useSelector(
    (state: {
      GlobalFilterReducers: {
        globalFilter: GetRecommendedTourCitiesPublicFilterBy;
        error: any;
      };
    }) => state.GlobalFilterReducers
  );
  const defaultFilter: FilterDto = {
    pageIndex: 0,
    pageSize: 20,
  };
  const defaultFilterBy: GetRecommendedTourCitiesPublicFilterBy = {
    carSearch:globalFilterReducers.globalFilter.carSearch,
  };
  // setFilter
  const [filter] = useState<FilterDto>(defaultFilter);
  const [defaultCar, setDefaultCar] = useState<GetDefaultCarTypeForPublicDto>();
  const [filterBy, setFilterBy] = useState<GetRecommendedTourCitiesPublicFilterBy>(defaultFilterBy);
  const [itemsContainerLocation, setItemsContainerLocation] = useState({ x: 0, y: 0 });
  const canvasRef = useRef<HTMLDivElement | null>(null);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  // setCanvasTitle
  const [canvasTitle] = useState({
    minHeight: 265,
    width: 500,
    x: 1415,
    y: 674,
  });
  const [images, setImages] = useState<Images[]>(DefaultImages.images);
  const getTotalHandler = (): number => {
    return (
      (filterBy &&
        filterBy.carSearch.reduce((total, item) => {
          total += item.adults.length || 0;
          total += item.childrens.length || 0;
          total += item.infants.length || 0;
          return total;
        },0)) ||
      0
    );
  };
  const getDefaultCarType = useCallback(async () => {
    const response: GetDefaultCarTypeForPublicDto = await GetDefaultCarTypeForPublic();
    setDefaultCar((response && response.carTypesId && response) || undefined);
  }, []);
  const moverHandler = (event: React.MouseEvent<HTMLDivElement>): void => {
    if (!canvasRef.current) return;
    const element: HTMLDivElement = canvasRef.current as HTMLDivElement;
    const elementChild: HTMLDivElement = element.firstChild as HTMLDivElement;
    const originalPointX: number = (element.offsetWidth - elementChild.offsetWidth) / 2 || 0;
    const originalPointY: number = (element.offsetHeight - elementChild.offsetHeight) / 2 || 0;
    const moveAmountX: number =
      (Math.abs(event.pageX) - element.offsetWidth / 2) / (element.offsetWidth / 2);
    const moveAmountY: number =
      (Math.abs(event.pageY) - element.offsetHeight / 2) / (element.offsetHeight / 2);
    const moveToX: number = originalPointX + originalPointX * moveAmountX;
    const moveToY: number = originalPointY + originalPointY * moveAmountY;
    setItemsContainerLocation({
      x: (moveToY > elementChild.offsetWidth / 2 && elementChild.offsetWidth) || moveToX,
      y: (moveToY > elementChild.offsetHeight / 2 && elementChild.offsetHeight) || moveToY,
    });
  };
  const locationsInit = useCallback((): void => {
    if (!canvasRef.current) return;
    const element: HTMLDivElement = canvasRef.current as HTMLDivElement;
    const elementChild: HTMLDivElement = element.firstChild as HTMLDivElement;
    setItemsContainerLocation((item) => {
      item.x = (element.offsetWidth - elementChild.offsetWidth) / 2 || 0;
      item.y = (element.offsetHeight - elementChild.offsetHeight) / 2 || 0;
      return { ...item };
    });
  }, []);
  const toursHandler = useCallback(async () => {
    setIsLoading(true);
    const toSaveDate: GetRecommendedTourCitiesPublicFilterBy = {
      ...globalFilterReducers.globalFilter,
      carSearch: globalFilterReducers.globalFilter.carSearch.filter(
        (item) =>
          item.adults.length !== 0 || item.childrens.length !== 0 || item.infants.length !== 0
      ),
    };
    const response: GetRecommendedTourCitiesPublicDto | any = await GetRecommendedTourCitiesPublic(
      filter.pageIndex + 1,
      filter.pageSize,
      toSaveDate
    );
    if ((response as Errors).data && (response as Errors).data.ErrorId) {
      showError(t(`${translationPath}search-failed`));
    } else {
      const result: GetRecommendedTourCitiesPublicDto = response as GetRecommendedTourCitiesPublicDto;
      setImages((items) => {
        items = items.map((item, index) => {
          if (index < (result&& result.result.length)) {
            item.imageUrl =
              (result.result[index].tourSummaryTrips.length > 0 &&
                result.result[index].tourSummaryTrips[0].tripCoverImage) ||
              defaultImage;
            item.tourId = result.result[index].tourId;
            item.tourName = result.result[index].tourName;
            item.totalRate = result.result[index].totalRate;
            item.totalTrips = result.result[index].tourSummaryTrips.length;
          }
          return { ...item };
        });
        return [...items];
      });
    }
    setIsLoading(false);
  }, [filter.pageIndex, filter.pageSize, globalFilterReducers.globalFilter, t]);
  const searchHandler = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setIsLoading(true);
   localStorage.setItem('globalFilter', JSON.stringify(filterBy));
    await Promise.resolve(dispatch(GlobalFilterActions.update(filterBy)));
  };
  const updateGloabalFilter = useCallback(() => {
    if (!defaultCar) return;
    dispatch(
      GlobalFilterActions.updateSuccess({
        ...filterBy,
        carSearch: filterBy.carSearch.map((item) => {
          item.carTypeId = defaultCar.carTypesId || null;
          return item;
        }),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCar]);
  useEffect(() => {
    locationsInit();
    localStorage.setItem('globalFilter', JSON.stringify(filterBy));
    // itemsLocationsInit();
  }, [locationsInit,filterBy]);
  useEventListener('resize', () => {
    locationsInit();
  });
  useEffect(() => {
    getDefaultCarType();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //  }, [getDefaultCarType,globalFilterReducers]);
  useEffect(() => {
    if (defaultCar && defaultCar.carTypesId) updateGloabalFilter();
  }, [defaultCar, updateGloabalFilter]);

  useEffect(() => {
    if (
      globalFilterReducers &&
      globalFilterReducers.globalFilter &&
      !globalFilterReducers.error &&
      globalFilterReducers.globalFilter.carSearch.findIndex(
        (item: CarSearchDto) => !item.carTypeId
      ) === -1
    )
      toursHandler();
    }, [globalFilterReducers, toursHandler]);
  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);
  return (
    (!(!isLoading && (images.length === 0 || !images[0].tourId)) && (
      <div className="tours-discover-view-wrapper childs-wrapper">
        <SpinnerUiComponent isActive={isLoading} isAbsolute />
        <div
          className="canvas-wrapper"
          ref={canvasRef}
          onMouseOver={(event: React.MouseEvent<HTMLDivElement>) => {
            if (timerRef.current) clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
              moverHandler(event);
            }, 50);
          }}
        >
          <div
            className="canvas-items-wrapper"
            style={{
              transform: `translate(${itemsContainerLocation.x}px,${itemsContainerLocation.y}px)`,
            }}
          >
            {images.map(
              (item, index) =>
                item.imageUrl && (
                  <div
                    className="canvas-item-wrapper"
                    key={`canvasItemRef${index}`}
                    style={{
                      transform: `translate(${item.x}px,${item.y}px)`,
                      width: item.width,
                      height: item.height,
                    }}
                  >
                    <LoadableImageUiComponant
                      type={LoadableImageEnum.div}
                      src={(item.imageUrl && getDownloadableLink(item.imageUrl)) || defaultImage}
                      alt={item.imageTitle}
                      isSkeleton
                      classes="canvas-image"
                    />
                    {/* <img
                      src={(item.imageUrl && getDownloadableLink(item.imageUrl)) || defaultImage}
                      alt={item.imageTitle}
                      className="canvas-image"
                    /> */}
                    <div className="hover-item-wrapper">
                      <div className="d-inline-flex-v-center fj-end p-3">
                        <span
                          className={`mdi mdi-heart${
                            (item.isFavorite && ' c-primary') || '-outline'
                          } mdi-24px`}
                        />
                      </div>
                      <div className="hover-item-content">
                        <div className="d-flex-v-center px-1">
                          <span className="fw-bold px-1">{item.tourName}</span>
                          <span className="mdi mdi-star c-yellow" />
                          <span>{(item.totalRate && Math.round(item.totalRate)) || 0}</span>
                        </div>
                        <div className="w-100 px-2">
                          <span>{item.totalTrips}</span>
                          <span className="px-1">{t(`${translationPath}destinations`)}</span>
                        </div>
                        <div className="w-100 px-2">
                          <ButtonBase className="btns theme-solid">
                            <span>{t(`${translationPath}add-to-cart`)}</span>
                          </ButtonBase>
                        </div>
                      </div>
                    </div>
                  </div>
                )
            )}
            <div
              className="canvas-item-wrapper canvas-title-wrapper"
              style={{
                transform: `translate(${canvasTitle.x}px,${canvasTitle.y}px)`,
                width: canvasTitle.width,
                minHeight: canvasTitle.minHeight,
              }}
            >
              <div className="canvas-title">
                <span>{t(`${translationPath}title-description-1`)}</span>
                <span>{t(`${translationPath}title-description-2`)}</span>
                <span>{t(`${translationPath}title-description-3`)}</span>
              </div>
            </div>
          </div>
          {filterBy && (
            <form noValidate className="canvas-filter-wrapper" onSubmit={searchHandler}>
              <div className="canvas-filter-items-wrapper">
                <div className="canvas-filter-item">
                  <AutocompleteUiComponent
                    idRef="whereToGoRef"
                    inputPlaceholder="select-location"
                    labelValue="where-to-go"
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    inputStartAdornment={<span className="mdi mdi-magnify" />}
                    getOptionSelected={(option) => option.key === 'zzzzzzz'}
                    options={[]}
                    getOptionLabel={(option) => option.value || ''}
                    // onKeyUp={searchHandler}
                    themeClass="theme-dark"
                    withBackdrop
                    onChange={(event, newValue) => {}}
                  />
                </div>
                <div className="canvas-filter-item">
                  <DateRangePickerUiComponent
                    ranges={[]}
                    // disabledDates={[moment().add(-1, 'w').toDate()]}
                    // minDate={moment().toDate()}
                    themeClass="theme-dark"
                    labelValue="when"
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    withBackdrop
                  />
                </div>
                <div className="canvas-filter-item">
                  <DropdownUiComponent
                    idRef="passengersRef"
                    labelValue="passengers"
                    wrapperClasses="theme-dark"
                    popoverClasses="theme-dark"
                    themeClass="theme-dark"
                    titleIcon="mdi mdi-account"
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    withBackdrop
                    contentComponent={() => (
                      <span> 
                        {filterBy &&
                          filterBy.carSearch &&
                          filterBy.carSearch.length > 0 &&
                          getTotalHandler()}
                        <span className="px-1">{t(`${translationPath}passengers`)}</span>
                      </span>
                    )}
                    popoverComponent={
                      <PassengersView
                        filterBy={filterBy}
                        defaultCar={defaultCar}
                        onFilterByChanged={(newValue: GetRecommendedTourCitiesPublicFilterBy) => {
                          setFilterBy({ ...newValue });
                        }}
                        translationPath={translationPath}
                        parentTranslationPath={parentTranslationPath}
                      />
                    }
                  />
                </div>
                <div className="canvas-filter-item">
                  <DropdownUiComponent
                    idRef="carsRef"
                    labelValue="cars"
                    wrapperClasses="theme-dark"
                    popoverClasses="theme-dark"
                    titleIcon="mdi mdi-car"
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    withBackdrop
                    contentComponent={() => (
                      <span>
                        {(getTotalHandler() > 0 && filterBy.carSearch.length) || 0}
                        <span className="px-1">{t(`${translationPath}cars`)}</span>
                      </span>
                    )}
                    popoverComponent={
                      <CarsView
                        filterBy={filterBy}
                        defaultCar={defaultCar}
                        onFilterByChanged={(newValue: GetRecommendedTourCitiesPublicFilterBy) =>
                          setFilterBy({ ...newValue })
                        }
                        translationPath={translationPath}
                        parentTranslationPath={parentTranslationPath}
                      />
                    }
                  />
                </div>
              </div>
              <div className="canvas-filter-btns-wrapper">
                <ButtonBase type="submit" className="btns">
                  <span>{t(`${translationPath}search`)}</span>
                </ButtonBase>
              </div>
            </form>
          )}
        </div>
      </div>
    )) ||
    null
  );
};
