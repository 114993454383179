import HeaderEn from '../Views/MainLayoutViews/Common/Header/I18n/en.json';
import HeaderAr from '../Views/MainLayoutViews/Common/Header/I18n/ar.json';
import FooterEn from '../Views/MainLayoutViews/Common/Footer/I18n/en.json';
import FooterAr from '../Views/MainLayoutViews/Common/Footer/I18n/ar.json';
import LandingEn from '../Views/MainLayoutViews/HomeLayoutsViews/LandingView/I18n/en.json';
import LandingAr from '../Views/MainLayoutViews/HomeLayoutsViews/LandingView/I18n/ar.json';
import MainLayoutEn from '../Layouts/MainLayout/I18n/en.json';
import MainLayoutAr from '../Layouts/MainLayout/I18n/ar.json';
import ToursRecommendedAr from '../UiComponentes/ToursRecommendedPoint//I18n/ar.json';
import ToursRecommendedEn from '../UiComponentes/ToursRecommendedPoint//I18n/en.json';
import OfferAr from '../Views/MainLayoutViews/HomeLayoutsViews/LandingView/Sections/OfferSectionView/I18n/ar.json';
import OfferEn from '../Views/MainLayoutViews/HomeLayoutsViews/LandingView/Sections/OfferSectionView/I18n/en.json';
import DownloadAr from '../Views/MainLayoutViews/HomeLayoutsViews/LandingView/Sections/DownloadMobileAppView/I18n/ar.json';
import DownloadEn from '../Views/MainLayoutViews/HomeLayoutsViews/LandingView/Sections/DownloadMobileAppView/I18n/en.json';
import ServicesEn from '../Views/MainLayoutViews/HomeLayoutsViews/LandingView/Sections/ServicesView/I18n/en.json';
import ServicesAr from '../Views/MainLayoutViews/HomeLayoutsViews/LandingView/Sections/ServicesView/I18n/ar.json';
import AllToursEn from '../Views/AllToursLayoutViews/AllToursViews/ToursView/I18n/en.json';
import AllToursAr from '../Views/AllToursLayoutViews/AllToursViews/ToursView/I18n/ar.json';
import LoginEn from '../Views/AccountsLayoutViews/LoginView/I18n/en.json';
import LoginAr from '../Views/AccountsLayoutViews/LoginView/I18n/ar.json';
import RegisterAccountEn from '../Views/AccountsLayoutViews/RegisterAccountView/I18n/en.json';
import RegisterAccountAr from '../Views/AccountsLayoutViews/RegisterAccountView/I18n/ar.json';
import VerificationCodeEn from '../Views/AccountsLayoutViews/VerificationCodeView/I18n/en.json';
import VerificationCodeAr from '../Views/AccountsLayoutViews/VerificationCodeView/I18n/ar.json';
import CreateAccountEn from '../Views/AccountsLayoutViews/CreateAccountView/I18n/en.json';
import CreateAccountAr from '../Views/AccountsLayoutViews/CreateAccountView/I18n/ar.json';
import BestToursAr from '../UiComponentes/RectangleBestTours/I18n/ar.json';
import BestToursEn from '../UiComponentes/RectangleBestTours/I18n/en.json';
import UserViewsUIAr from '../Views/UserLayoutViews/UserViews/UserViewsUI/I18n/ar.json';
import UserViewsUIEn from '../Views/UserLayoutViews/UserViews/UserViewsUI/I18n/en.json';
import FavoriteTourAr from '../Views/FavoriteLayoutViews/FavoriteViews/FavoriteToursView//I18n/ar.json';
import FavoriteTourEn from '../Views/FavoriteLayoutViews/FavoriteViews/FavoriteToursView/I18n/en.json';
import FavoriteToursUiComponentAr from '../UiComponentes/FavoriteCard/I18n/ar.json';
import FavoriteToursUiComponentEn from '../UiComponentes/FavoriteCard/I18n/en.json';
import MyToursViewAr from '../Views/MyToursViews/MyTours/I18n/ar.json';
import MyToursViewEn from '../Views/MyToursViews/MyTours/I18n/en.json';
import i18next from 'i18next';
import { GlobalRerender } from './Middleware.Helper';

export function localizationInit() {
  i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: 'en', // language to use
    resources: {
      en: {
        Header: HeaderEn,
        Footer: FooterEn,
        Landing: LandingEn,
        Download: DownloadEn,
        ToursRecommended: ToursRecommendedEn,
        Offer: OfferEn,
        Services: ServicesEn,
        BestTours: BestToursEn,
        Login: LoginEn,
        FavoriteTours: FavoriteTourEn,
        FavoriteToursUI: FavoriteToursUiComponentEn,
        MainLayout: MainLayoutEn,
        RegisterAccount: RegisterAccountEn,
        VerificationCode: VerificationCodeEn,
        CreateAccount: CreateAccountEn,
        AllTours: AllToursEn,
        UserViewsUI: UserViewsUIEn,
        MyToursViews: MyToursViewEn,
      },
      ar: {
        Header: HeaderAr,
        Footer: FooterAr,
        Landing: LandingAr,
        Download: DownloadAr,
        ToursRecommended: ToursRecommendedAr,
        Offer: OfferAr,
        Services: ServicesAr,
        BestTours: BestToursAr,
        Login: LoginAr,
        FavoriteTours: FavoriteTourAr,
        FavoriteToursUI: FavoriteToursUiComponentAr,
        MainLayout: MainLayoutAr,
        RegisterAccount: RegisterAccountAr,
        VerificationCode: VerificationCodeAr,
        CreateAccount: CreateAccountAr,
        AllTours: AllToursAr,
        UserViewsUI: UserViewsUIAr,
        MyToursViews: MyToursViewAr,
      },
    },
  });

  if (localStorage.getItem('localization')) {
    i18next.changeLanguage(
      JSON.parse(localStorage.getItem('localization') as string).currentLanguage
    );
    const isRtl =
      JSON.parse(localStorage.getItem('localization') as string).currentLanguage === 'ar';
    if (isRtl) {
      const direction =
        JSON.parse(localStorage.getItem('localization') as string).currentLanguage === 'ar'
          ? 'rtl'
          : '';
      document.body.setAttribute('class', direction);
      document.body.setAttribute('dir', direction);
      document.documentElement.lang = JSON.parse(
        localStorage.getItem('localization') as string
      ).currentLanguage;
    }
  } else {
    localStorage.setItem('localization', JSON.stringify({ currentLanguage: 'en', isRtl: false }));
    i18next.changeLanguage('en');
  }
}

export const languageChange = (currentLanguage: string) => {
  const isRtl = currentLanguage === 'ar';
  const direction = currentLanguage === 'ar' ? 'rtl' : '';
  localStorage.setItem('localization', JSON.stringify({ currentLanguage, isRtl }));
  document.body.setAttribute('class', direction);
  document.body.setAttribute('dir', direction);
  document.documentElement.lang = currentLanguage;
  i18next.changeLanguage(currentLanguage);
  GlobalRerender();
};
