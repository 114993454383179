import React, {useState} from 'react';
import {SetGlobalRerender} from "./Helpers";
import {BrowserRouter as Router} from 'react-router-dom';
import {SwitchRoute} from "./UiComponentes";
import {AppRoutes} from "./Route";
import 'moment/locale/ar';
import moment from 'moment';
 import CarSearchCheck  from './Helpers/CarSearch.Helper';

function App() {
 const [ render, setRender] = useState(false);
 SetGlobalRerender(setRender,render);
 moment.locale('en');
  return (
      <>
      <Router>
          <SwitchRoute routes={AppRoutes} />
      </Router>
   <CarSearchCheck/> 
      </>
  );
}

export default App;
