import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../../Helpers/HttpMethod.Helper';
import {
    GetRecommendedSearchTour 
  } from './Interfaces/Tours.Interface';
export const GetSearchTours = async (
    pageIndex: number,
    pageSize: number,
    body: GetRecommendedSearchTour
  ): Promise<GetRecommendedSearchTour |any> => {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/Tours/SearchTours?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      body,
      { headers: { isPublic: false } }
    )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  
    return result;
  };

  export const GetSearchPublicTours = async (
    pageIndex: number,
    pageSize: number,
    body: GetRecommendedSearchTour
  ): Promise<GetRecommendedSearchTour |any> => {
      console.log(body,'body')
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/Tours/SearchToursForPublic/${config.applicationId}/${config.organizationsId}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      body,
      { headers: { isPublic: true } }
    )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  
    return result;
  };
