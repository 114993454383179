import { useEffect,useState } from "react";
import { useDispatch } from "react-redux";
import { GetDefaultCarTypeForPublic, GetDefaultCarTypeForPublicDto } from "../Services";
import { GlobalFilterActions } from "../Stores/Actions";


export const CarSearchCheck = (props: any) => {

  const [filter , setFilter ] = useState( {carSearch: [{ carTypeId: null, adults: ['adult 1'], childrens: [], infants: [] }]})
  let global = ''
  global = JSON.stringify(localStorage.getItem('globalFilter'))
  const storage = JSON.parse(JSON.parse(global))
  const dispatch = useDispatch(); 
  const getDefaultCarType = async () => {

      if(storage && storage.carSearch && !storage.carSearch){
    const response: GetDefaultCarTypeForPublicDto = await GetDefaultCarTypeForPublic();
   if (response && response.carTypesId ){
    setFilter((items:any)=>{
      const carsearch = items.carSearch
 carsearch[0].carTypeId=response.carTypesId
        return { ...items, carsearch };
             })
          dispatch(GlobalFilterActions.update(filter));
   } 

      }
  }
  useEffect(() => {
    getDefaultCarType();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (<><div></div></>);
}
export default CarSearchCheck;