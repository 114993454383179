import * as React from 'react';
import {
  GetAllOffersPublic,
  GetAllToursByOfferId,
} from '../../../../../../Services/Offers/GetAllOffers.Public';
import moment from 'moment';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getDownloadableLink } from '../../../../../../Helpers';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import { useSelector } from 'react-redux';
import { CarSearchDto, GetRecommendedTourCitiesPublicFilterBy } from '../../../../../../Services';
import { SpinnerUiComponent } from '../../../../../../UiComponentes';
export const OfferSectionView = (props: any) => {
  const { t } = useTranslation('Offer');
  const globalFilterReducers = useSelector(
    (state: {
      GlobalFilterReducers: {
        globalFilter: GetRecommendedTourCitiesPublicFilterBy;
        error: any;
      };
    }) => state.GlobalFilterReducers
  );
  const [result, setResult] = React.useState<any[]>([]);
  const [resFisrtLode, setresFisrtLode] = React.useState<any>({
    tripCoverImage: '',
    totalFromPriceAfterDiscount: '',
    isFavorite: '',
    totalFromPrice: '',
    address: '',
    Distinations: '',
    tripName: '',
  });
  const [resultTour, setResultTour] = React.useState<any[]>([]);
  console.log('resultTour: ', resultTour);
  const [index] = React.useState(0);
  const [ShowOfferSection, setshowOfferSection] = React.useState(true);
  const [filterGalary] = React.useState({ pageIndex: 1, pageSize: 100 });
  const [pageIndexOffers, setpageIndexOffers] = React.useState(1);
  const [currentActiveTour, setCurrentActiveTour] = React.useState(0);
  const [filter, setFilter] = React.useState({ pageIndex: 1, pageSize: 1 });
  const [filterby, setfilterby] = React.useState<GetRecommendedTourCitiesPublicFilterBy>({
    carSearch: [{ carTypeId: null, adults: [''], childrens: [], infants: [] }],
  });
  const [totalOffers, setTotalOffers] = React.useState<number>(0);
  const [totalTours, setTotalTours] = React.useState<number>(0);
  const [pagesizeOffers] = React.useState(10 * filterGalary.pageSize);
  const [lock, setlock] = React.useState(false);
  const [move, setmove] = React.useState(false);
  const [date, setDate] = React.useState({ startDate: moment(), endDate: moment() });
  const [isLoading, setIsLoading] = React.useState(false);
  const [FirstLode, setFirstLode] = React.useState(true);
  const nextOffer = () => {
    if (filter.pageIndex + 1 <= totalOffers) {
      setFilter((items) => ({ ...items, pageIndex: items.pageIndex + 1 }));
    } else {
      setFilter((items) => ({ ...items, pageIndex: 1 }));
    }
  };
  const previousOffer = () => {
    if (filter.pageIndex > 1) {
      setFilter((items) => ({ ...items, pageIndex: items.pageIndex - 1 }));
    } else {
      setFilter((items) => ({ ...items, pageIndex: 1 }));
    }
  };
 

  const nextTour = () => {
    setFirstLode(false);
    if (currentActiveTour === totalTours - 1 || currentActiveTour === totalTours) {
      setmove(true);  
      setCurrentActiveTour(0);
      setTimeout(
        () =>     setmove(false), 
        1000
      );
    } else {
      setmove(true);
      setpageIndexOffers((item) => item + 1);  
      setCurrentActiveTour((item) => item + 1);  
      setTimeout(
        () =>     setmove(false), 
        800
      );
    }
  };
  const previousTour = () => {
    if (currentActiveTour === 0) {
      setCurrentActiveTour(totalTours - 1);
    } else {
      setCurrentActiveTour((item) => item - 1);
    }
  };

  const GetAllToursByOfferApi = React.useCallback(
    async (Data: any, pageIndex: number, PageSize: number, payload: any) => {
      const res = await GetAllToursByOfferId(Data[0].offerId, pageIndex, PageSize, payload);
      setResultTour(
        ((res as any).result.length > 0 && (res as any).result) ||
          Data.map((item: { tours: Object }) => ({ ...item.tours })) ||
          []
      );
      try {
        setTotalTours((res as any).totalCount);
      } catch {
        setTotalTours((res as any).totalCount);
      }
    },
    []
  );

  const GetAllOffersApi = React.useCallback(async () => {
    setIsLoading(true);
    setFirstLode(true);
    const res = await GetAllOffersPublic(filter.pageIndex, filter.pageSize, filterby);
    res && setResult((res as any).result || []);
    res && res.totalCount && setTotalOffers((res as any).totalCount);
    setCurrentActiveTour(0);
    if (res && (res as any).totalCount !== 0) {
      if (res && (res as any).result.length) {
        setDate({
          endDate: moment((res as any).result[index].endDateTime),
          startDate: moment((res as any).result[index].startDateTime),
        });
        setresFisrtLode({
          tripCoverImage: (res as any).result[0].tours.tourSummaryTrips[0].tripCoverImage,
          totalFromPriceAfterDiscount: (res as any).result[0].tours.totalFromPriceAfterDiscount,
          isFavorite: (res as any).result[0].tours.isFavorite,
          totalFromPrice: (res as any).result[0].tours.totalFromPrice,
          address: (res as any).result[0].tours.tourSummaryTrips[0].address,
          Distinations: (res as any).result[0].tours.tourSummaryTrips.length,
          tripName: (res as any).result[0].tours.tourSummaryTrips[0].tripName,
        });
        setIsLoading(false);
        GetAllToursByOfferApi((res as any).result, pageIndexOffers, pagesizeOffers, filterby);
      } else setIsLoading(false);
    } else {
      setshowOfferSection(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filter.pageIndex,
    index,
    filterby,
    pagesizeOffers,
    filter.pageSize,
    filterGalary.pageIndex,
    pageIndexOffers,
    GetAllToursByOfferApi,
  ]);

  React.useEffect(() => {
    if (
      globalFilterReducers &&
      globalFilterReducers.globalFilter &&
      !globalFilterReducers.error &&
      globalFilterReducers.globalFilter.carSearch.findIndex(
        (item: CarSearchDto) => !globalFilterReducers.globalFilter.carSearch
      ) === -1
    )
      setfilterby(globalFilterReducers.globalFilter);
  }, [globalFilterReducers]);
  React.useEffect(() => {
    if (filterby.carSearch && filterby.carSearch.length > 0 && filterby.carSearch[0].carTypeId)
      GetAllOffersApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, filterby.carSearch]);
  //  }, [GetAllOffersApi, filter, filterby]);
  React.useEffect(() => {
    if (totalOffers === filter.pageIndex) {
      setlock(true);
    } else {
      setlock(false);
    }
  }, [filter.pageIndex, totalOffers]);
  //===========================================****--Timer--****===========================================\\
  const timerRef = React.useRef<ReturnType<typeof setInterval> | null>(null);
  React.useEffect(() => {
    if (!timerRef.current) counterHandler();
  });
  React.useEffect(() => {
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, []);
  const counterHandler = (): void => {
    timerRef.current = setInterval(() => {
      setDate((item) => {
        if (item.startDate.isSameOrAfter(item.endDate)) {
          if (timerRef.current) clearInterval(timerRef.current);
          return item;
        }
        const localStart = item.startDate;
        return { ...item, startDate: moment(localStart).add(1, 'm') };
      });
    }, 60000);
  };
  //===========================================****--Timer--****===========================================\\

  return (
    <div>
      {ShowOfferSection === true ? (
        <div className='OfferSection-MAIN childs-wrapper'>
          <div className='offer-section-content-wrapper'>
            <div className='Seaction-Titel-contenar'>
              <div className='seaction-titel'>{t('TOURS')}</div>
              <div className='seaction-titel-main'>{t('Offeredtour')}</div>
            </div>
            <div className='OfferSection'>
              <div className='Conteaner'>
                <SpinnerUiComponent isActive={isLoading} isAbsolute />
                {result && result.length > index && result[index] && (
                  <>
                    <div className='Timer-Seaction'>
                      <div className='Header-Timer-Seaction'>
                        <div className='Percentage-Num'>
                          {t('Discounts')} {result[index].discountPercentage || ''}%{' '}
                        </div>
                        <div className='Ends-Offer'>
                          {t('Ends-in')}{' '}
                          {Math.floor(moment.duration(date.endDate.diff(date.startDate)).asDays())}{' '}
                          {t('days')},{moment.duration(date.endDate.diff(date.startDate)).hours()}{' '}
                          {t('hours')} {t('and')}{' '}
                          {moment.duration(date.endDate.diff(date.startDate)).minutes()} {t('min')}
                        </div>
                      </div>
                      <div className='Conteaner-Timer-Seaction'>
                        <div className='time-item-wrapper'>
                          <span className='time-name'>{t('Days')}</span>
                          <span>
                            {Math.floor(
                              moment.duration(date.endDate.diff(date.startDate)).asDays()
                            )}
                          </span>
                        </div>
                        <div className='time-item-wrapper'>
                          <span className='time-name'>{t('hours')}</span>
                          <span>{moment.duration(date.endDate.diff(date.startDate)).hours()}</span>
                        </div>
                        <div className='time-item-wrapper'>
                          <span className='time-name'>{t('minutes')}</span>
                          <span>
                            {moment.duration(date.endDate.diff(date.startDate)).minutes()}
                          </span>
                        </div>
                        <div className='description-name'>
                          {result[index].description || 'No Description '}
                        </div>
                      </div>
                      <div className='footer-Timer-Seaction'>
                        <ButtonBase
                          onClick={previousOffer}
                          className='btns theme-outline mr-2-reversed'
                          disabled={filter.pageIndex === 1 ? true : false}>
                          <span className='mdi mdi-chevron-left' />
                          <span className='w-100'>{t('Back')}</span>
                        </ButtonBase>
                        <ButtonBase
                          disabled={lock}
                          className='btns theme-outline ml-2-reversed'
                          onClick={nextOffer}>
                          <span className='w-100'>{t('Next')}</span>
                          <span className='mdi mdi-chevron-right' />
                        </ButtonBase>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className='Gallery-Conteaner'>
                {(FirstLode !== true ? (
                  resultTour &&
                  resultTour[currentActiveTour] &&
                  !isLoading && (
                    <>
                      <div className='wr-se'>
                        <div className='Gallery-container-waraper'>
                          <div className='Sub-Image-contenar' />
                          <div
                            className={move===true? "First-Image-contenar-move":"First-Image-contenar"}
                            style={{
                              backgroundImage: `url(${getDownloadableLink(
                                resultTour[currentActiveTour].tourSummaryTrips[0].tripCoverImage
                              )})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              backgroundSize: 'cover',
                            }}
                          />
                          <span className='favorite'>
                            <span
                              className={
                                resultTour[currentActiveTour].tourSummaryTrips[0].isFavorite ===
                                true
                                  ? 'mdi mdi-heart-outline favoritconclick '
                                  : 'mdi mdi-heart-outline favoriticon'
                              }></span>
                          </span>
                          <div className='Image-contenar' />

                          <div className='none'>
                            <div className='contenar-place-name'>
                              <span className='text-place-name'>
                                {resultTour[currentActiveTour].tourSummaryTrips[0].tripName}
                              </span>{' '}
                              <span className='mdi mdi-star Rating-Star' />
                              <span className='Number-Rating-Star'>{5}</span>
                            </div>
                            <div>
                              <span className='text-num-Distinations'>
                                {resultTour[currentActiveTour].tourSummaryTrips.length}{' '}
                              </span>
                              <span className='text-name-Distinations'>{t('Distinations')} </span>
                            </div>
                            <div className='text-from'>{t('from')}</div>
                            <div className='price-contenar'>
                              <span className='price-from-mobile'>{t('from')}</span>
                              <span className='text-old-price'>
                                {resultTour[currentActiveTour].totalFromPrice} {t('AED')}
                              </span>{' '}
                              <span className='text-new-price'>
                                {resultTour[currentActiveTour].totalFromPriceAfterDiscount}{' '}
                                {t('AED')}
                              </span>
                            </div>
                            <ButtonBase className='btns'>{t('AddtoCart')}</ButtonBase>
                          </div>
                          <div className='d-flex-center flex-wrap mb-2 IconButton-conteaner'>
                            <IconButton
                              onClick={previousTour}
                              disabled={currentActiveTour === 0 ? true : false}
                              className='MuiButtonBase-root btns-icons mx-2 mb-2'>
                              <span className='mdi mdi-chevron-left'></span>
                              <span className='MuiTouchRipple-root'></span>
                            </IconButton>
                            <IconButton
                              onClick={nextTour}
                              className='MuiButtonBase-root btns-icons mx-2 mb-2'
                              disabled={move}
                              type='button'>
                              <span className='mdi mdi-chevron-right'></span>
                              <span className='MuiTouchRipple-root'></span>
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                ) : (
                  <div className='wr-se'>
                    <div className='Gallery-container-waraper'>
                      <div className='Sub-Image-contenar' /> 
                      <div
                                 className='First-Image-contenar'
                        style={{
                          backgroundImage: `url(${getDownloadableLink(
                            resFisrtLode.tripCoverImage
                          )})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                        }}
                      />
                      <span className='favorite'>
                        <span
                          className={
                            resFisrtLode.isFavorite === true
                              ? 'mdi mdi-heart-outline favoritconclick '
                              : 'mdi mdi-heart-outline favoriticon'
                          }></span>
                      </span>
                      <div className='Image-contenar' />
                      <div className='none'>
                        <div className='contenar-place-name'>
                          <span className='text-place-name'>{resFisrtLode.tripName}</span>{' '}
                          <span className='mdi mdi-star Rating-Star' />
                          <span className='Number-Rating-Star'>{5}</span>
                        </div>
                        <div>
                          <span className='text-num-Distinations'>
                            {resFisrtLode.Distinations}{' '}
                          </span>
                          <span className='text-name-Distinations'>{t('Distinations')} </span>
                        </div>
                        <div className='text-from'>{t('from')}</div>
                        <div className='price-contenar'>
                          <span className='price-from-mobile'>{t('from')}</span>
                          <span className='text-old-price'>
                            {resFisrtLode.totalFromPrice} {t('AED')}{' '}
                          </span>
                          <span className='text-new-price'>
                            {resFisrtLode.totalFromPriceAfterDiscount} {t('AED')}{' '}
                          </span>
                        </div>{' '}
                        <ButtonBase className='btns'>{t('AddtoCart')}</ButtonBase>{' '}
                      </div>
                      <div className='d-flex-center flex-wrap mb-2 IconButton-conteaner'>
                        <IconButton
                          onClick={previousTour}
                          disabled={currentActiveTour === 0 ? true : false}
                          className='MuiButtonBase-root btns-icons mx-2 mb-2'>
                          <span className='mdi mdi-chevron-left'></span>
                          <span className='MuiTouchRipple-root'></span>
                        </IconButton>
                        <IconButton
                          onClick={nextTour}
                          className='MuiButtonBase-root btns-icons mx-2 mb-2'
                          type='button'>
                          <span className='mdi mdi-chevron-right'></span>
                          <span className='MuiTouchRipple-root'></span>
                        </IconButton>
                      </div>
                    </div>
                  </div>
                )) || (
                  <>
                    <Skeleton className='loder' animation='wave' />
                    <div className='d-flex-center flex-wrap mb-2 IconButton-conteaner-skelton'>
                      <IconButton
                        disabled={true}
                        className='MuiButtonBase-root btns-icons mx-2 mb-2'>
                        <span className='mdi mdi-chevron-left'></span>
                        <span className='MuiTouchRipple-root'></span>
                      </IconButton>
                      <IconButton
                        disabled={true}
                        className='MuiButtonBase-root btns-icons mx-2 mb-2'
                        type='button'>
                        <span className='mdi mdi-chevron-right'></span>
                        <span className='MuiTouchRipple-root'></span>
                      </IconButton>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
