import React, { ChangeEvent, useCallback } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
interface CheckboxesUiComponentProps {
  idRef: string;
  data?: any[];
  ariaLabel?: string;
  labelValue?: string;
  translationPath?: string;
  parentTranslationPath?: string;
  translationPathForData?: string;
  onSelectedCheckboxChanged?: any;
  checked?: any | boolean;
  indeterminate?: any | boolean;
  key?: string;
  isDisabledInput?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  isRow?: boolean;
  labelInput?: string;
  label?: string;
  wrapperClasses?: string;
  checkboxClasses?: string;
  labelClasses?: string;
  themeClass?:  'theme-dark' |string;
  tabIndex?: number;
  disableRipple?: boolean;
}
export const CheckboxesUiComponent = ({
  data,
  labelValue,
  ariaLabel,
  translationPath = '',
  parentTranslationPath = '',
  translationPathForData = '',
  onSelectedCheckboxChanged = '',
  key = 'checkboxGroups',
  isDisabledInput,
  isDisabled,
  labelInput,
  label,
  wrapperClasses = '',
  checkboxClasses = '',
  labelClasses = '',
  themeClass,
  isRequired,
  idRef,
  checked,
  indeterminate,
  isRow,
  tabIndex,
  disableRipple,
}: CheckboxesUiComponentProps): React.ReactElement => {
  const { t } = useTranslation(parentTranslationPath);
  const onChangeHandler = useCallback(
    (item, index) => (event: React.ChangeEvent<{}>, checked: boolean) => {
      if (onSelectedCheckboxChanged)
        onSelectedCheckboxChanged(item, index, checked, event) as (
          item: object,
          index: number,
          checked: boolean,
          event: ChangeEvent<{}>
        ) => void;
    },
    [onSelectedCheckboxChanged]
  );
  return (
    <FormControl
      required={isRequired}
      className={`checkbox-groups-wrapper ${wrapperClasses} ${themeClass || ''}`}
      component="fieldset"
    >
      {labelValue && (
        <label
          htmlFor={idRef}
          className={`label-wrapper ${labelClasses}${isDisabled ? ' disabled' : ''}`}
        >
          {t(`${translationPath}${labelValue}`)}
        </label>
      )}
      {!data && (
        <FormControlLabel
          disabled={isDisabled}
          className={`form-control-label ${labelClasses}` }
          tabIndex={tabIndex}
          control={
            <Checkbox
              className={`checkbox-wrapper ${checkboxClasses}`}
              icon={<span className="i-unchecked" />}
              checkedIcon={<span className="mdi mdi-check" />}
              indeterminateIcon={<span className="mdi mdi-minus" />}
              checked={checked}
              disableRipple={disableRipple}
              indeterminate={indeterminate}
            />
          }
          label={t(`${translationPath}${label}`)}
          onChange={onSelectedCheckboxChanged as (event: ChangeEvent<{}>, checked: boolean) => void}
          id={idRef}
        />
      )}
      {data && (
        <FormGroup
          aria-label={ariaLabel ? t(`${translationPathForData}${ariaLabel}`) : 'Checkbox Group'}
          row={isRow}
          className="checkbox-group-wrapper"
          id={idRef}
        >
          {data.map((item, index) => (
            <FormControlLabel
              key={`${key}${index + 1}`}
              disabled={isDisabledInput ? item[isDisabledInput] : isDisabled}
              className="form-control-label"
              onChange={onChangeHandler(item, index)}
              control={
                <Checkbox
                  className={`checkbox-wrapper ${checkboxClasses}`}
                  icon={<span className="i-unchecked" />}
                  checkedIcon={<span className="mdi mdi-check" />}
                  indeterminateIcon={<span className="mdi mdi-minus" />}
                  checked={checked && checked(item, index)}
                  indeterminate={indeterminate && indeterminate(item, index)}
                />
              }
              label={
                label || (labelInput ? t(`${translationPathForData}${item[labelInput]}`) : item)
              }
            />
          ))}
        </FormGroup>
      )}
    </FormControl>
  );
};
