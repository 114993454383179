import React from 'react';
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteGetTagProps,
  AutocompleteInputChangeReason,
  AutocompleteRenderOptionState,
} from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import { InputsUiComponent } from '../Inputs/Inputs.UiComponent';
interface AutocompleteUiComponantProps {
  onChange: (
    event: React.ChangeEvent<{}>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined
  ) => void;
  options: any[];
  getOptionLabel?: (option: any) => string;
  getOptionSelected?: (option: any, value: any) => boolean;
  disableClearable?: boolean;
  chipsLabel?: (option: any, index: any) => string;
  value?: any;
  defaultValue?: any[];
  isLoading?: boolean;
  multiple?: boolean;
  inputValue?: string;
  onInputChange?: (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void;
  getOptionDisabled?: (option: any) => boolean;
  renderOption?: (option: any, state: AutocompleteRenderOptionState) => React.ReactNode;
  renderTags?: (value: any[], getTagProps: AutocompleteGetTagProps) => React.ReactNode;
  chipsDisabled?: Function;
  onKeyUp?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  translationPath?: string;
  parentTranslationPath?: string;
  labelValue?: string;
  inputPlaceholder?: string;
  idRef: string;
  wrapperClasses?: string;
  autocompleteClasses?: string;
  variant?: 'outlined' | 'standard' | 'filled';
  inputWrapperClasses?: string;
  inputClasses?: string;
  themeClass?: 'theme-dark';
  helperText?: string;
  inputLabel?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  error?: boolean;
  isWithError?: boolean;
  isSubmitted?: boolean;
  withBackdrop?: boolean;
  // PropTypes.oneOfType([PropTypes.elementType, PropTypes.func, PropTypes.node])
  inputStartAdornment?: React.ReactElement;
  overInputIcon?: string;
  beforeIconClasses?: string;
  afterIconClasses?: string;
  popperClasses?: string;
  dropdownIcon?: string;
  dropdownCloseIcon?: string;
  withSearchIcon?: boolean;
  paddingReverse?: string | number;
  buttonOptions?: {
    className: string;
    iconClasses: string;
    onActionClicked: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isDisabled: boolean;
  };
}
export const AutocompleteUiComponent = ({
  onChange,
  options,
  getOptionLabel,
  renderOption,
  defaultValue,
  isLoading = false,
  value,
  onKeyUp,
  onKeyDown,
  inputValue,
  onInputChange,
  getOptionDisabled,
  chipsLabel,
  chipsDisabled = () => false,
  translationPath = '',
  parentTranslationPath = '',
  labelValue,
  inputPlaceholder,
  idRef = 'autocompleteRef',
  wrapperClasses = '',
  autocompleteClasses = '',
  themeClass,
  variant = 'standard',
  multiple,
  isDisabled,
  isRequired,
  inputClasses,
  inputWrapperClasses,
  helperText,
  error,
  isWithError,
  isSubmitted,
  inputLabel,
  withSearchIcon,
  buttonOptions,
  disableClearable,
  getOptionSelected,
  renderTags,
  overInputIcon,
  beforeIconClasses,
  afterIconClasses,
  inputStartAdornment,
  dropdownIcon = 'mdi mdi-chevron-down',
  popperClasses = '',
  dropdownCloseIcon = 'mdi mdi-close',
  paddingReverse,
  withBackdrop,
}: AutocompleteUiComponantProps): React.ReactElement => {
  return (
    <div className={`autocomplete-wrapper ${wrapperClasses} ${themeClass || ''}`}>
      {withSearchIcon && (
        // <Button className="btns-icon theme-transparent mx-2">
        <span className="c-black-light mdi mdi-magnify mdi-24px mx-1" />
        // </Button>
      )}
      <Autocomplete
        multiple={multiple}
        disableClearable={disableClearable}
        id={idRef}
        className={`autocomplete ${autocompleteClasses}`}
        classes={{
          popper: `autocomplete-popper-wrapper ${popperClasses} ${themeClass || ''}${
            (withBackdrop && ' with-backdrop') || ''
          }`,
        }}
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        renderOption={renderOption}
        defaultValue={defaultValue}
        loading={isLoading}
        value={value}
        inputValue={inputValue}
        onInputChange={onInputChange}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onChange={onChange}
        getOptionDisabled={getOptionDisabled}
        disabled={isDisabled}
        closeIcon={<span className={`${dropdownCloseIcon} dropdown-close-icon-wrapper`}></span>}
        popupIcon={<span className={`${dropdownIcon} dropdown-icon-wrapper`}></span>}
        renderTags={
          renderTags ||
          ((tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                className="autocomplete-chip"
                label={chipsLabel && chipsLabel(option, index)}
                key={`${idRef}chip${index + 1}`}
                {...getTagProps({ index })}
                disabled={chipsDisabled(option, index)}
              />
            )))
        }
        renderInput={(params) => (
          <InputsUiComponent
            idRef={idRef}
            label={inputLabel}
            labelValue={labelValue}
            autoCompleteParams={params}
            inputPlaceholder={inputPlaceholder}
            variant={variant}
            isWithError={isWithError}
            isSubmitted={isSubmitted}
            paddingReverse={paddingReverse}
            isRequired={isRequired}
            wrapperClasses={inputWrapperClasses}
            fieldClasses={inputClasses}
            startAdornment={inputStartAdornment}
            beforeIconClasses={beforeIconClasses}
            afterIconClasses={afterIconClasses}
            overInputIcon={overInputIcon}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            buttonOptions={buttonOptions}
            themeClass={themeClass}
            error={error}
            helperText={helperText}
          />
        )}
      />
    </div>
  );
};
