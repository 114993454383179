import { useRef, useEffect } from 'react';

function useEventListener(
  eventName: string,
  handler: Function,
  element: any = window,
  passiveDto?: Object
) {
  const savedHandler = useRef<Function>();
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;
    const eventListener = (event: Event) => {
      if (savedHandler?.current) {
        savedHandler.current(event);
      }
    };
    element.addEventListener(eventName, eventListener, passiveDto);
    return () => element.removeEventListener(eventName, eventListener);
  }, [eventName, element, passiveDto]);
}
export { useEventListener };
