import React from 'react';
import image from '../../Assets/Images/Defaults/loader.svg';
interface SpinnerUiComponentProps {
  isActive: boolean;
  isAbsolute?: boolean;
}
export const SpinnerUiComponent = ({
  isActive,
  isAbsolute,
}: SpinnerUiComponentProps): React.ReactElement => (
  <>
    {isActive && (
      <div className={`spinner-wrapper${isAbsolute ? '  is-absolute' : ''}`}>
        <div className="app-spinner-wrapper">
          <img src={image} alt="..." className="app-spinner" />
          <span className="app-please-wait">Please Wait ...</span>
        </div>
      </div>
    )}
  </>
);
