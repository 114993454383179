import {
  LoginView,
  VerificationCodeView,
  RegisterAccountView,
  CreateAccountView,
} from '../../../Views';

export const AccountsRoute: any = [
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    layout: '/accounts',
    default: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '/registeraccount',
    name: 'RegisterAccount',
    component: RegisterAccountView,
    layout: '/accounts',
    default: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '/verificationcode',
    name: 'VerificationCode',
    component: VerificationCodeView,
    layout: '/accounts',
    default: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '/CreateAccount',
    name: 'CreateAccount',
    component: CreateAccountView,
    layout: '/accounts',
    default: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
];
