import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../../Helpers/HttpMethod.Helper';

export const GetMyBasket = async () => {
    try {
      const result = await HttpServices.get(
        `${config.serverAddress}/PSTViewing/BasketTours/GetMyBasket`,
        { headers: { isPublic: false } }
      );
      return result;
    } catch (e) {
      return;
    }
  };
