import * as React from 'react';
import { ButtonBase } from '@material-ui/core';
import {
  Timeline,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
} from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { getDownloadableLink } from '../../Helpers/Middleware.Helper';
import { LoadableImageEnum } from '../../Enums';
import default_img from '../../Assets/Images/Defaults/Defaults_tours.png';
import { LoadableImageUiComponant } from '../LoadableImage/LoadableImage.UiComponant';
export const ToursRecommendedPointUiComponent = (props: any) => {
  const { t } = useTranslation('ToursRecommended');
  return (
    <div className="card-point-container">
      <div className="main-title">{props.Data.tourName} </div>
      <div>
        <span className="from">{t('from')}</span>
        <span className="price-title">
          {props.Data.totalFromPriceAfterDiscount}
          {t('AED')}
        </span>
      </div>
      <div className="card-point">
        {(props.Data.tourSummaryTrips && props.Data.tourSummaryTrips.length > 0 && (
          <Timeline>
            {props.Data.tourSummaryTrips &&
              props.Data.tourSummaryTrips.map((ItemDetales: any, index: number) => (
                <TimelineItem key={index}>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <div className="details">
                      <div className="detaile-name">{ItemDetales.tripName}</div>
                      <div className="mdi mdi-star Rating">{ItemDetales.totalTripRating}</div>
                    </div>
                    <div className="Image-container">
                      <LoadableImageUiComponant
                        classes="Image"
                        type={LoadableImageEnum.div}
                        alt={`$user-image`}
                        src={getDownloadableLink(ItemDetales.tripCoverImage)}
                      />
                    </div>
                  </TimelineContent>
                </TimelineItem>
              ))}
          </Timeline>
        )) || (
          <LoadableImageUiComponant
            classes="Image"
            type={LoadableImageEnum.div}
            alt={`$user-image`}
            src={default_img}
          />
        )}
      </div>
      <div className="contact-field">
        <ButtonBase className="btns">{t('Addtocart')}</ButtonBase>
        <div
          className={
            props.Data.isFavorite !== 'true'
              ? 'mdi mdi-heart-outline favoritebuuton'
              : 'mdi mdi-heart favoritebuutonON'
          }
        />
      </div>
    </div>
  );
};
