import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadableImageEnum } from '../../../../../../Enums';
import { LoadableImageUiComponant } from '../../../../../../UiComponentes';
import Man from '../../../../../../Assets/Images/Defaults/man.svg';
import { ButtonBase } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { useLocalStorage, useEventListener } from '../../../../../../Hooks';
const translationPath = 'reviewView.';
const parentTranslationPath = 'Landing';
export const ReviewView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const [currentDirection] = useLocalStorage<{ isRtl: boolean }>('localization', { isRtl: false });
  const cardsWrapperRef = useRef<HTMLDivElement | null>(null);
  const scrollTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [scrollCurrentItem, setScrollCurrentItem] = useState(0);
  const [navigators, setNavigators] = useState({
    previous: false,
    next: false,
  });
  const [reviews] = useState([1, 2, 3, 4, 5, 6]);
  const getIsScrollable = useCallback(
    (direction: string): boolean => {
      if (!cardsWrapperRef.current) return false;
      const isRtl: boolean = currentDirection.isRtl;
      const element: HTMLDivElement = cardsWrapperRef.current as HTMLDivElement;
      const maxScrollLeft: number = Math.round(element.scrollWidth - element.clientWidth);
      if (
        (direction === 'previous' &&
          ((!isRtl && (element.scrollLeft <= 0 || scrollCurrentItem <= 0)) ||
            (isRtl &&
              (Math.abs(element.scrollLeft) >= maxScrollLeft || scrollCurrentItem <= 0)))) ||
        (direction === 'next' &&
          ((!isRtl &&
            (element.scrollLeft >= maxScrollLeft || scrollCurrentItem >= reviews.length - 1)) ||
            (isRtl &&
              (Math.abs(element.scrollLeft) >= maxScrollLeft ||
                scrollCurrentItem >= reviews.length - 1))))
      )
        return false;
      return true;
    },
    [currentDirection.isRtl, reviews.length, scrollCurrentItem]
  );
  const toHandler = (direction: string) => () => {
    const element: HTMLDivElement = cardsWrapperRef.current as HTMLDivElement;
    if (!getIsScrollable(direction)) return;
    setScrollCurrentItem((item) => {
      if (direction === 'next') item += 1;
      else item -= 1;
      element.scrollTo({
        left: (element.firstChild?.childNodes[item] as HTMLDivElement).offsetLeft,
        behavior: 'smooth',
      });
      return item;
    });
  };
  useEffect(() => {
    setNavigators({
      next: !getIsScrollable('next'),
      previous: !getIsScrollable('previous'),
    });
  }, [getIsScrollable, reviews]);
  useEffect(() => {
    setScrollCurrentItem(0);
  }, [currentDirection.isRtl]);
  useEffect(() => {
    return () => {
      if (scrollTimer.current) clearTimeout(scrollTimer.current);
    };
  }, []);
  useEventListener('resize', () => {
    setNavigators({
      next: !getIsScrollable('next'),
      previous: !getIsScrollable('previous'),
    });
  });
  return (
    <div className='review-view-wrapper childs-wrapper'>
      <div className='review-title-wrapper'>
        <span className='title-section'>{t(`${translationPath}title-description-1`)}</span>
        <span className='title-section'>{t(`${translationPath}title-description-2`)}</span>
      </div>
      <div className='w-100 d-flex-center flex-wrap mb-2'>
        <ButtonBase
          className='btns-icons mx-2 mb-2'
          disabled={navigators.previous}
          onClick={toHandler('previous')}>
          <span className='mdi mdi-chevron-left' />
        </ButtonBase>
        <ButtonBase
          className='btns-icons mx-2 mb-2'
          disabled={navigators.next}
          onClick={toHandler('next')}>
          <span className='mdi mdi-chevron-right' />
        </ButtonBase>
      </div>
      <div
        className='cards-wrapper'
        ref={cardsWrapperRef}
        onScroll={() => {
          if (scrollTimer.current) clearTimeout(scrollTimer.current);
          scrollTimer.current = setTimeout(() => {
            setNavigators({
              next: !getIsScrollable('next'),
              previous: !getIsScrollable('previous'),
            });
          }, 50);
        }}>
        <div className='card-items-wrapper'>
          {reviews &&
            reviews.map((item, index) => (
              <div className='card-item-wrapper' key={`reviewRef${index}`}>
                <div className='card-item-content-wrapper'>
                  <div className='card-item-header'>
                    <LoadableImageUiComponant
                      classes='card-item-image'
                      type={LoadableImageEnum.div}
                      alt={t(`${translationPath}user-image`)}
                      src={
                        // (item.coverTripImageId && getDownloadableLink(item.coverTripImageId)) ||
                        Man
                      }
                    />
                    <div className='d-inline-flex-column px-2'>
                      <span className='fw-bold'>Artem Sazonov</span>
                      <Rating
                        name='user-rating'
                        defaultValue={5}
                        precision={0.5}
                        size='small'
                        icon={<span className='mdi mdi-star' />}
                        disabled
                        emptyIcon={<span className='mdi mdi-star-outline' />}
                      />
                    </div>
                  </div>
                  <div className='card-item-body'>
                    <span>
                      It’s not only about what you write but how you present it. Only those who risk
                      going too far can possibly find out how far one can go.
                    </span>
                  </div>
                  <div className='card-item-footer'>
                    <span className='mdi mdi-map-marker' />
                    <span className='px-2'>Burj khaleefa</span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
