import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../../Helpers/HttpMethod.Helper';

export const GetLookupItems = async ({ pageIndex, pageSize, lookupTypeName }: any) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Lookups/LookupItem/${pageIndex}/${pageSize}?lookupTypeName=${lookupTypeName}`
    );
    return result;
  } catch (e) {
    // if (e.response && e.response) throw e.response;
    // else throw e;
    return;
  }
};

export const lookupItemsGet = async ({
  pageIndex,
  pageSize,
  lookupTypeName,
  lookupParentId,
  searchedItem,
}: any) => {
  try {
    const result = await HttpServices.get(
      `${
        config.serverAddress
      }/Lookups/LookupItem/${pageIndex}/${pageSize}?lookupTypeName=${lookupTypeName}${
        lookupParentId ? `&lookupParentId=${lookupParentId}` : ''
      }&search=${searchedItem}`
    );
    return result;
  } catch (e) {
    return;
  }
};

export const getLookupItems = async ({ lookupTypeName, lookupParentId }: any) => {
  const queryList = [];
  lookupTypeName && queryList.push(`lookupTypeName=${lookupTypeName}`);
  lookupParentId && queryList.push(`lookupParentId=${lookupParentId}`);
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Lookups/LookupItem?${queryList.join('&')}`
    );
    return result;
  } catch (e) {
    return;
  }
};
export const lookupItemsGetId = async (payload: any) => {
  const { lookupTypeId, lookupParentId } = payload;

  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Lookups/LookupItem/${lookupTypeId}${
        lookupParentId ? `?lookupParentId=${lookupParentId}` : ''
      }`
    );
    return result;
  } catch (e) {
    return;
  }
};

export const lookupItemsPost = async (payload: any) => {
  const { body } = payload;
  try {
    const result = await HttpServices.post(`${config.serverAddress}/Lookups/LookupItem`, body);
    return result;
  } catch (e) {
    return;
  }
};

export const lookupItemsPut = async (payload: any) => {
  const { body, lookupItemId } = payload;
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/Lookups/LookupItem/${lookupItemId}`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};

export const lookupItemsDelete = async (payload: any) => {
  const { lookupItemId } = payload;
  try {
    const result = await HttpServices.delete(
      `${config.serverAddress}/Lookups/LookupItem/${lookupItemId}`
    );
    return result;
  } catch (e) {
    return;
  }
};

export const lookupTypesGet = async (payload: any) => {
  const { pageIndex, pageSize, searchedItem } = payload;
  try {
    const result = await HttpServices.get(
      searchedItem
        ? `${config.serverAddress}/Lookups/LookupType/${pageIndex}/${pageSize}?search=${searchedItem}`
        : `${config.serverAddress}/Lookups/LookupType/${pageIndex}/${pageSize}`
    );
    return result;
  } catch (e) {
    return;
  }
};
export const lookupTypesPut = async (payload: any) => {
  const { body, lookupTypeId } = payload;
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/Lookups/LookupType/${lookupTypeId}`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};

export const lookupTypesDelete = async (payload: any) => {
  const { lookupTypeId } = payload;
  try {
    const result = await HttpServices.delete(
      `${config.serverAddress}/Lookups/LookupType/${lookupTypeId}`
    );
    return result;
  } catch (e) {
    return;
  }
};

export const lookupTypesPost = async (body: any) => {
  try {
    const result = await HttpServices.post(`${config.serverAddress}/Lookups/LookupType`, body);
    return result;
  } catch (e) {
    return;
  }
};

export const GetPublicLookupItem = async ({ lookupTypeName, lookupParentId ,pageSize ,pageIndex }: any) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0) queryList.push(`pageIndex=${pageIndex + 1}`);
  lookupTypeName && queryList.push(`lookupTypeName=${lookupTypeName}`);
  lookupParentId && queryList.push(`lookupParentId=${lookupParentId}`);
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Lookups/LookupItem/GetPublicLookupItem/${config.organizationsId}/${config.applicationId}?${queryList.join('&')}`
    );
    return result;
  } catch (e) {
    return;
  }
};
