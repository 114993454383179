import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute.UiComponentes';

function SwitchRoute(props: any) {
  const route = props.routes.find((f: any) => f.default === true);
  return (
    <Switch>
      {props.routes.map((value: any, key: number) => {
        if (!value.isRoute) return null;
        if (value.authorize) {
          return (
            <PrivateRoute
              path={value.layout + value.path}
              component={value.component}
              key={`privateRoute${key + 1}`}
              addRoute={value.addRoute}
            />
          );
        }
        return (
          <Route
            path={value.layout + value.path}
            component={value.component}
            key={`privateRoute${key + 1}`}
          />
        );
      })}

      <Redirect exact from={route.layout} to={route.layout + route.path} />
      <Route path="*" />
    </Switch>
  );
}
export { SwitchRoute };
