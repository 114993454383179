import loadable from '@loadable/component';
const AccountsLayout = loadable(() => import('../../Layouts/AccountsLayout/Accounts.Layout'));
const MainLayout = loadable(() => import('../../Layouts/MainLayout/Main.layout'));

export const AppRoutes: any = [
  {
    path: '/accounts',
    name: 'Accounts',
    component: AccountsLayout,
    layout: '',
    default: false,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '',
    name: '',
    component: MainLayout,
    layout: '',
    default: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
];
