import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../../Helpers';
import { Errors } from '../Interfaces';
import {
  LoginDto,
  LoginResponseDto,
  SocialMediaLoginDto,
  VerificationApplicationUserDto,
} from './Interfaces';

export const CheckExistUserName = async (username: string) => {
  const result = await HttpServices.post(
    `${config.serverAddress}/Identity/Account/CheckExistUserName`,
    {
      identity: username,
    }
  )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
};
export const CheckExistEmail = async (username: string) => {
  const result = await HttpServices.post(
    `${config.serverAddress}/Identity/Account/CheckExistEmail`,
    {
      identity: username,
    }
  )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
};
export const CheckExistPhone = async (username: string) => {
  const result = await HttpServices.post(
    `${config.serverAddress}/Identity/Account/CheckExistPhone`,
    {
      identity: username,
    }
  )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
};

export const LoginService = async (payload: LoginDto) => {
  const result = await HttpServices.post(
    `${config.serverAddress}/Identity/Account/ApplicationLogin`,
    payload
  )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
};

export const VerificationApplicationUserService = async (
  payload: VerificationApplicationUserDto
) => {
  const result = await HttpServices.post(
    `${config.serverAddress}/Identity/Account/VerificationApplicationUser`,
    payload
  )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
};

export const VerifyApplicationUserCodeService = async (payload: VerificationApplicationUserDto) => {
  const result = await HttpServices.post(
    `${config.serverAddress}/Identity/Account/VerifyApplicationUserCode`,
    payload
  )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
};

export const SignupApplicationUserService = async (payload: VerificationApplicationUserDto) => {
  const result = await HttpServices.post(
    `${config.serverAddress}/Identity/Account/SignupApplicationUser`,
    payload
  )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
};

export const SocialMediaLogin = async (
  payload: SocialMediaLoginDto
): Promise<LoginResponseDto | Errors> => {
  const result = await HttpServices.post(
    `${config.serverAddress}/Identity/Account/ApplicationSocialMediaLogin`,
    payload
  )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
};
export const Logout = async (): Promise<any | Errors> => {
  const result = await HttpServices.post(`${config.serverAddress}/Identity/Account/logout`)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
};
export const GetUserProfile = async (): Promise<any | Errors> => {
  const result = await HttpServices.get(`${config.serverAddress}/Identity/Account/GetUserProfile`)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
};

export const EditMyProfileAPI = async (payload: any): Promise<any | Errors> => {
  const result = await HttpServices.put(
    `${config.serverAddress}/Identity/Account/EditMyProfile`,
    payload
  )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
};

export const ChangePasswordAPI = async (payload: any): Promise<any | Errors> => {
  const result = await HttpServices.put(
    `${config.serverAddress}/Identity/Account/ChangePassword`,
    payload
  )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
};
