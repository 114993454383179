import { Skeleton } from '@material-ui/lab';
import React, { useState, useEffect, useRef } from 'react';
import image from '../../Assets/Images/Defaults/loader.gif';
import { LoadableImageEnum } from '../../Enums';
interface LoadableImageUiComponantProps {
  src: string;
  alt: string;
  type: LoadableImageEnum;
  classes: string;
  isSkeleton?: boolean;
  style?: Object;
}
export const LoadableImageUiComponant = ({
  src,
  alt,
  type,
  classes,
  isSkeleton,
  ...props
}: LoadableImageUiComponantProps): React.ReactElement => {
  const [state, setState] = useState(false);
  const mounted = useRef(true);
  useEffect(() => {
    if (type === LoadableImageEnum.div) {
      const bgImg = new Image();
      bgImg.src = src;
      bgImg.onload = function () {
        if (mounted.current) setState(true);
      };
    }
  }, [mounted, src, type]);
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);
  return (
    <>
      {type === LoadableImageEnum.image && (
        <img
          src={src}
          alt={alt}
          className={`${classes} ${(!state && ' d-none') || ''}`}
          onLoad={() => {
            setState(true);
          }}
          {...props}
        />
      )}
      {type === LoadableImageEnum.div && state && (
        <div
          className={classes}
          aria-label={alt}
          {...props}
          style={{
            ...props.style,
            backgroundImage: `url(${src})`,
          }}
        />
      )}
      {(!isSkeleton && (
        <img
          src={image}
          alt={alt}
          className={`${classes} ${(state && ' d-none') || ''}`}
          {...props}
        />
      )) ||
        (!state && (
          <Skeleton animation="wave" height="100%" width="100%" className="loadable-skeleton" />
        ))}
    </>
  );
};
