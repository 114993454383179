import { ButtonBase } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
// import { useHistory } from 'react-router';
import { showError, showSuccess } from '../../../../../../Helpers';
import { Errors, LoginResponseDto } from '../../../../../../Services';
import { LoginActions } from '../../../../../../Stores/Actions';

export const UserMenuView = ({ translationPath = 'UserMenuView.' }) => {
  const { t } = useTranslation('Header');
  const [fromLogout, setFromLogout] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  // const history = useHistory();
  const loginReducers = useSelector(
    (state: { LoginReducers: { loginResponse: LoginResponseDto | Errors; error: any } }) =>
      state.LoginReducers
  );
  const logoutHandler = () => {
    dispatch(LoginActions.logout());
    setFromLogout(true);
  };
  const accountHandler = () => {
    history.push('/user');
  };
  const mytoursHandler = () => {
    history.push('/MyTours');
  };
  const logout = useCallback(() => {
    if (!loginReducers || !loginReducers.loginResponse) {
      localStorage.removeItem('session');
      showSuccess(t(`${translationPath}logout-successfully`));
      // history.push('/accounts/login');
    } else if (loginReducers && loginReducers.error)
      showError(t(`${translationPath}logout-failed`));
  }, [loginReducers, t, translationPath]);
  useEffect(() => {
    if (fromLogout) logout();
  }, [fromLogout, loginReducers, logout]);
  return (
    <div className='user-menu-view childs-view'>
      <div className='user-menu-item-wrapper'>
        <ButtonBase className='user-menu-item' onClick={accountHandler}>
          <div className='user-menu-icon-wrapper'>
            <span className='mdi mdi-account-outline' />
          </div>
          <span className='px-2'>{t(`${translationPath}manage-account`)}</span>
        </ButtonBase>
        <ButtonBase className='user-menu-item' onClick={mytoursHandler}>
          <div className='user-menu-icon-wrapper'>
            <span className='mdi mdi-send-outline mdi-rotate-45' />
          </div>
          <span className='px-2'>{t(`${translationPath}my-tours`)}</span>
        </ButtonBase>
        <ButtonBase className='user-menu-item'>
          <div className='user-menu-icon-wrapper'>
            <span className='mdi mdi-history' />
          </div>
          <span className='px-2'>{t(`${translationPath}recently-viewed`)}</span>
        </ButtonBase>
        <ButtonBase className='user-menu-item' onClick={logoutHandler}>
          <div className='user-menu-icon-wrapper bg-warning'>
            <span className='mdi mdi-logout' />
          </div>
          <span className='px-2'>{t(`${translationPath}logout`)}</span>
        </ButtonBase>
      </div>
    </div>
  );
};
