import { GlobalFilterStates } from '../../States';

function reset(payload: any) {
  return { type: GlobalFilterStates.RESET, payload };
}

function update(payload: any) {
  return { type: GlobalFilterStates.UPDATE, payload };
}
function updateSuccess(payload: any) {
  return { type: GlobalFilterStates.UPDATE_SUCCESS, payload };
}
function updateFailed(payload: any) {
  return { type: GlobalFilterStates.UPDATE_FAILED, payload };
}

export const GlobalFilterActions = {
  updateSuccess,
  updateFailed,
  update,
  reset,
};
