import React, { ChangeEvent, FormEvent, useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputsUiComponent, SpinnerUiComponent } from '../../../UiComponentes';
import Joi from 'joi';
import { useTitle } from '../../../Hooks';
import { showError, getErrorByName, showSuccess, showInfo  } from '../../../Helpers';
import { ButtonBase } from '@material-ui/core';
import config from '../../../Assets/Configration/Configration.json';
import { useDispatch, useSelector } from 'react-redux';
import { LoginActions } from '../../../Stores/Actions';
import { LoginResponseDto } from '../../../Services/Account';
import { useHistory } from 'react-router';
import { Errors ,VerificationApplicationUserService } from '../../../Services';
// @ts-ignore
import { OldSocialLogin as SocialLogin } from 'react-social-login';
import TwitterLogin from 'react-twitter-login';
import { SocialMediaProviderType } from '../../../Enums';
const translationPath = '';
const parentTranslationPath = 'RegisterAccount';
export const RegisterAccountView = () => {
  const { t } = useTranslation(parentTranslationPath);
  // const [isView, setIsView] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  // const facebookRef = useRef<React.>(null)
  const loginResponse = useSelector(
    (state: { LoginReducers: { loginResponse: LoginResponseDto | Errors } }) =>
      state.LoginReducers.loginResponse
  );
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    else if (action.id === 'edit') {
      return {
        ...action.value,
      };
    }
  }, []);
  useTitle(t(`${translationPath}Signup`));
  const [state, setState] = useReducer(reducer, {
    identity: '',
    organizationsId: config.organizationsId,
    applicationsId: config.applicationId,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setisActive] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const schema = Joi.object({
    identity: Joi.string()
      .required()
      .messages({
        'string.empty': t(`${translationPath}email-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);
  // const loginHandler = async (): Promise<void> => {
  //   setIsLoading(true);
  //   await dispatch(LoginActions.login(state));
  //   setIsLoading(false);
  // };
  const loginResponseHandler = useCallback(() => {
    if (loginResponse) {
      if ((loginResponse as LoginResponseDto).token) {
        localStorage.setItem('session', JSON.stringify(loginResponse));
        showSuccess(t(`${translationPath}login-successfully`));
        history.push('/');
      } else if ((loginResponse as Errors).data && (loginResponse as Errors).data.ErrorId)
        showError(t(`${translationPath}${(loginResponse as Errors).data.Message}`));
    }
  }, [history, loginResponse, t]);
  const handleSocialLogin = (from: 'Google' | 'Facebook') => async (user: any, err: any) => {
    if (user) {
      setIsLoading(true);
      await dispatch(
        LoginActions.login({
          socialMediaProviderTypeId: SocialMediaProviderType[from],
          token: user._token.accessToken,
          privateToken: null,
          organizationId: state.organizationId,
          applicationId: state.applicationId,
        })
      );
      setIsLoading(false);
    }
  };
  const authHandler = async (err: any, data: any) => {
    if (data) {
      setIsLoading(true);
      await dispatch(
        LoginActions.login({
          socialMediaProviderTypeId: SocialMediaProviderType.Twitter,
          token: data.oauth_token,
          privateToken: data.oauth_token_secret,
          organizationId: state.organizationId,
          applicationId: state.applicationId,
        })
      );
      setIsLoading(false);
    }
  };
  const saveHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (schema.error) {
      showError(t('please-fix-all-errors'));
      return;
    }
    // VerificationApplicationUser();
  };
  useEffect(() => {
    loginResponseHandler();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginResponse ]);

  const VerificationApplicationUser = React.useCallback(async () => {
    // setloading(true);
    const response = await VerificationApplicationUserService(state);
    if ((response as Errors).data && (response as Errors).data.ErrorId) {
      showError(t(`${translationPath}failed`));
    } else {
      showInfo(t(`${translationPath}resend-code-successfully`));
      history.push(`/accounts/VerificationCode?id=${response.identityVerificationId}?Email=${state.identity}`)
    }
  }, [history, state, t]);

  return (
    <div className='RegisterAccount-wrapper views-wrapper'>
      <SpinnerUiComponent isActive={isLoading} />
      <div className='d-flex-v-center px-1'>
        <div className='login-header'>
          <span className='title-text'>{t(`${translationPath}Create-your-account`)}</span>

          <span>{t(`${translationPath}Get_started_with_PSTours`)}</span>
        </div>
        <div className='close-bbt'>
          <ButtonBase
            className='btns-icon theme-solid mx-2 mb-2'
            // disabled={scrollCurrentItem >= data.length - 1}
            onClick={() => history.push('/')}>
            <span className='mdi mdi-close mdi-36px' />
          </ButtonBase>
        </div>
      </div>
      <form noValidate className='login-body' onSubmit={saveHandler}>
        <div className='opation-Signup'>
          <ButtonBase
            className={
              isActive === 2
                ? 'opation-bbt theme-transparent'
                : 'opation-bbt theme-transparent isActive'
            }
            onClick={() => setisActive(1)}>
            <span className='opacity-1'>{t(`${translationPath}Email`)}</span>
          </ButtonBase>
          <ButtonBase
            className={
              isActive === 1
                ? 'opation-bbt theme-transparent'
                : 'opation-bbt theme-transparent isActive'
            }
            disabled
            onClick={() => setisActive(2)}>
            <span className='opacity-1'>{t(`${translationPath}phonenumber`)}</span>
          </ButtonBase>
        </div>

        <div className='body-item'>
          <InputsUiComponent
            idRef='usernameRef'
            inputPlaceholder='Emailaddress'
            value={state.identity}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            startAdornment={<span className='mdi mdi-email' />}
            themeClass='theme-dark'
            isSubmitted={isSubmitted}
            isWithError
            helperText={getErrorByName(schema, 'identity').message}
            error={getErrorByName(schema, 'identity').error}
            onInputChanged={(event: ChangeEvent<HTMLInputElement>) => {
              setState({ id: 'identity', value: event.target.value });
            }}
          />
        </div>
        <div className='body-item'>
          <ButtonBase
            type='submit'
            className='btns'
             onClick={() => {VerificationApplicationUser()}}>
            <span>{t(`${translationPath}Signup`)}</span>
          </ButtonBase>
        </div>
        <div className='body-item'>
          <span>{t(`${translationPath}or-connect-with`)}</span>
        </div>
      </form>
      <div className='login-footer'>
        <div className='footer-item'>
          <TwitterLogin
            authCallback={authHandler}
            consumerKey='GwRJUTPvnLWPtwZ67u72euT4b'
            consumerSecret='YFcWaP1602RimnhftCZvxKHNSQhxsznYqwqhYXu0R8URD3cgYu'
            children={
              <ButtonBase className='btns theme-dark theme-transparent br-0'>
                <span className='mdi mdi-twitter' />
                <span className='px-1'>{t(`${translationPath}twitter`)}</span>
              </ButtonBase>
            }
          />
        </div>
        <div className='footer-item'>
          <SocialLogin
            provider='facebook'
            appId={config.facebookAppKey}
            callback={handleSocialLogin('Facebook')}>
            <ButtonBase className='btns theme-dark theme-transparent br-0'>
              <span className='mdi mdi-facebook' />
              <span className='px-1'>{t(`${translationPath}facebook`)}</span>
            </ButtonBase>
          </SocialLogin>
        </div>
        <div className='footer-item'>
          <SocialLogin
            provider='google'
            appId={config.googleAppKey}
            callback={handleSocialLogin('Google')}>
            <ButtonBase className='btns theme-dark theme-transparent br-0'>
              <span className='mdi mdi-google' />
              <span className='px-1'>{t(`${translationPath}google`)}</span>
            </ButtonBase>
          </SocialLogin>
        </div>
      </div>
      <div className='outside-login'>
        <div className='outside-item'>
          <span>{t(`${translationPath}Already_on_Pstours-Let_us_take_you_to`)}</span>
          <ButtonBase
            className='btns theme-dark theme-transparent'
            onClick={() => {history.push('/accounts/login')}}>
            <span className='opacity-1'>{t(`${translationPath}Sign_In`)}</span>
          </ButtonBase>
        </div>
      </div>
    </div>
  );
};
