import { GetRecommendedTourCitiesPublicFilterBy } from '../../../Services';
import { GlobalFilterStates } from '../../States';
const defaultFilterBy: GetRecommendedTourCitiesPublicFilterBy = {
  carSearch: [{ carTypeId: null, adults: ['adult 1'], childrens: [], infants: [] }],
};
const init = {
  globalFilter: localStorage.getItem('globalFilter')
    ? JSON.parse(localStorage.getItem('globalFilter') as string)
    : defaultFilterBy,
  error: null,
} as {
  globalFilter: GetRecommendedTourCitiesPublicFilterBy;
  error: any;
};

export const GlobalFilterReducers = (
  state: {
    globalFilter: GetRecommendedTourCitiesPublicFilterBy;
  } = init,
  action: {
    type: any;
    payload: any;
  }
) => {
  switch (action.type) {
    case GlobalFilterStates.RESET:
      return {
        globalFilter: action.payload,
        error: null,
      };
    case GlobalFilterStates.UPDATE_SUCCESS:
      return {
        globalFilter: action.payload,
        error: null,
      };
    case GlobalFilterStates.UPDATE_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
