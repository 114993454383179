import React from 'react';
import {
  CustomTourView,
  OfferSectionView,
  ReviewView,
  SectionsView,
  ServicesView,
  ToursDiscoverView,
  DownloadMobileAppView
} from './Sections';

const LandingView = (): React.ReactElement => {
  return (
    <div className="landing-view-wrapper views-wrapper">
      <ToursDiscoverView />
      <CustomTourView />
      <OfferSectionView />
      <SectionsView />
      <ReviewView />
      <ServicesView />
      <DownloadMobileAppView />
    </div>
  );
};

export default LandingView;
